import {useCallback, useMemo} from 'react';
import TMapSender, {ECallbackKeys, TMapReceiver} from '@lcc/tmap-inapp';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {fetchUserData} from 'ducks/userInfo/slice';
import useMoveToTarget from './useMoveToTarget';
import {TPoiDetailParam} from 'types/App';
import {additionalInfoList, EAdditionalInfo} from 'ducks/tplacehome/types';

const usePlaceHome = () => {
  const dispatch = useAppDispatch();
  const {moveToDetail} = useMoveToTarget();
  const {personalPlace, tplacehome} = useAppSelector((state) => ({
    personalPlace: state.userInfo.personalPlace,
    tplacehome: state.tplacehome,
  }));

  const openPoiDetail = useCallback(
    (poiItem: Pick<TPoiDetailParam, 'poiId' | 'pkey' | 'navX' | 'navY'>) => {
      moveToDetail(poiItem);
    },
    [moveToDetail]
  );

  const toggleFavorite = useCallback(
    async (poiDataJson: {
      centerX?: string | number;
      centerY?: string | number;
      navSeq: string | number;
      navX: string | number;
      navY: string | number;
      pkey: string | number;
      poiId: string | number;
      poiName: string;
    }) => {
      return new Promise((resolve) => {
        TMapReceiver.setCallback(ECallbackKeys.FAVORITE, (isOn) => {
          dispatch(fetchUserData()); // callback받고 userdata 갱신 > 즐겨찾기 활성화
          resolve(isOn);
        });
        // 단말에 전송시 string타입으로 변경해야 함
        const params = {
          centerX: String(poiDataJson.centerX || ''),
          centerY: String(poiDataJson.centerY || ''),
          navSeq: String(poiDataJson.navSeq || ''),
          navX: String(poiDataJson.navX || ''),
          navY: String(poiDataJson.navY || ''),
          pkey: String(poiDataJson.pkey || ''),
          poiId: String(poiDataJson.poiId),
          poiName: poiDataJson.poiName,
        };
        // address, tel, rpFlag 는 기존 티지금 poi데이터에 없고, 즐겨찾기에 영향없는 것으로 보여 ignore. 필요시 api 추가요청 할 것.
        // @ts-ignore
        TMapSender.toggleFavorite(params);
      });
    },
    [dispatch]
  );

  const checkFavorite = useCallback(
    (poiItem: Pick<TPoiDetailParam, 'poiId' | 'pkey' | 'navX' | 'navY'>) => {
      const {favorites, favoritesPublic} = personalPlace.data;
      const favoriteItems = [...favorites, ...favoritesPublic];

      return favoriteItems.some(
        // @ts-ignore
        (item) => item.pkey === poiItem.pkey || item.poiId === poiItem.poiId
      );
    },
    [personalPlace.data]
  );

  // 서울, 경기만 예약/웨이팅 표시
  const isReservationAvailableRegion = useMemo(() => {
    const currentRegionName1 = tplacehome.aroundMeData.result.data.requestGeoInfo.regionName1;
    const waitingAvailableRegion = ['서울특별시', '경기도'];
    return waitingAvailableRegion.includes(currentRegionName1);
  }, [tplacehome.aroundMeData.result.data.requestGeoInfo.regionName1]);
  const computedAdditionalFilterList = useMemo(() => {
    if (isReservationAvailableRegion) {
      return additionalInfoList;
    } else {
      return additionalInfoList.filter((item) => item.value !== EAdditionalInfo.RESERVATION);
    }
  }, [isReservationAvailableRegion]);

  return {
    openPoiDetail,
    toggleFavorite,
    checkFavorite,
    isReservationAvailableRegion,
    computedAdditionalFilterList,
  };
};

export default usePlaceHome;
