import {CSSProperties, useState} from 'react';
import ImageLoader from 'components/ImageLoader';
import {TTPlaceCurationType2} from 'ducks/remote/type';
import {moveByLandingType} from 'utils/url';
import {IcLineBetween} from 'components/@tmds/icons/v1.2/IcLineBetween';
import s from 'styles/components/tplacehome/TPlaceCurationType2.module.scss';

type TProps = {
  item: TTPlaceCurationType2;
};

const TPlaceCurationType2 = ({item}: TProps) => {
  const [wrapHeight, setWrapHeight] = useState(0);

  return (
    <div
      className={s.wrap}
      style={
        {
          '--height': `${wrapHeight}px`,
        } as CSSProperties
      }
    >
      <div className={s.title_wrap}>
        <ImageLoader
          src={item.thumbnailUrl}
          alt={item.title}
          className={s.bg}
          onLoad={(e) => {
            const el = e.target as HTMLImageElement;

            if (el) {
              setWrapHeight(el.offsetHeight || 0);
            }
          }}
        />
        <div className={s.gradient} />

        <div className={s.title}>
          <h4 dangerouslySetInnerHTML={{__html: item.title}} />
          <span className={s.desc} dangerouslySetInnerHTML={{__html: item.desc}} />
        </div>
      </div>

      {item.list.length > 0 && (
        <ul className={s.list_wrap}>
          {item.list.map((v, i) => (
            <li
              key={i}
              className={s.item}
              onClick={() => {
                moveByLandingType(v.landingType, v.landingUrl);
              }}
            >
              <ImageLoader src={v.thumbnailUrl} alt={v.poiName} className={s.image} />
              <div className={s.poi_wrap}>
                <span className={s.poi_name}>{v.poiName}</span>

                <div className={s.detail_wrap}>
                  <span className={s.address}>{v.address}</span>
                  {/* TODO: color 수정 */}
                  <IcLineBetween color="#E0E2E8" width={10} height={10} />
                  <span className={s.category}>{v.category}</span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TPlaceCurationType2;
