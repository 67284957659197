import {PlaceCategoryLink} from 'components/PlaceCategoryLink';
import s from 'styles/components/tplacehome/TPlaceRankingStaticBanner.module.scss';

const RankingStaticBanner = () => {
  return (
    <div className={s.wrap}>
      <PlaceCategoryLink type="RANKING">
        <div className={s.inner_wrap}>
          <div className={s.content_wrap}>
            <div className={s.icon}>
              <div className={s.bg}>
                <svg
                  width="51"
                  height="44"
                  viewBox="0 0 51 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Group 1261157003">
                    <path
                      id="Line 144"
                      d="M5.2207 9L2 13.1279"
                      stroke="#3ECB71"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                    <path
                      id="Line 145"
                      d="M48.668 35L44.668 39"
                      stroke="#3ECB71"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                    <circle
                      id="Ellipse 749"
                      cx="44"
                      cy="7"
                      r="2"
                      stroke="#43C7F9"
                      strokeWidth="2"
                    />
                    <circle
                      id="Ellipse 750"
                      cx="9"
                      cy="41"
                      r="2"
                      stroke="#43C7F9"
                      strokeWidth="2"
                    />
                    <circle id="Ellipse 751" cx="19.5" cy="2.5" r="2.5" fill="#976CFB" />
                    <circle id="Ellipse 752" cx="36.5" cy="39.5" r="2.5" fill="#976CFB" />
                  </g>
                </svg>
              </div>
              <div className={s.img}>
                <svg
                  width="42"
                  height="42"
                  viewBox="0 0 42 42"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="trophy_1">
                    <path
                      id="Vector 127 (Stroke)"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M34.3736 8.44282C34.406 8.17976 34.155 7.97166 33.9025 8.05219L30.8464 9.02683L29.8494 5.90071L32.9055 4.92608C35.4376 4.11853 37.9547 6.20536 37.6304 8.84325L37.3649 11.0021C36.7673 15.8624 33.3219 19.8945 28.6143 21.2428L27.7108 18.0884C31.1524 17.1027 33.6713 14.1549 34.1082 10.6017L34.3736 8.44282Z"
                      fill="#F2B821"
                    />
                    <path
                      id="Vector 128 (Stroke)"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.62637 8.44282C7.59403 8.17976 7.84504 7.97166 8.09755 8.05219L11.1536 9.02683L12.1506 5.90071L9.09454 4.92608C6.56244 4.11853 4.04531 6.20536 4.36965 8.84325L4.63508 11.0021C5.23267 15.8624 8.67812 19.8945 13.3857 21.2428L14.2892 18.0884C10.8476 17.1027 8.32869 14.1549 7.89181 10.6017L7.62637 8.44282Z"
                      fill="#F2B821"
                    />
                    <path
                      id="Rectangle 1196"
                      d="M17.7628 23.1386L24.0685 23.1385C23.249 25.7363 23.421 28.5456 24.5514 31.024L25.2345 32.5219H16.7651L17.2478 31.0741C18.1001 28.5175 18.2775 25.784 17.7628 23.1386Z"
                      fill="#F2B821"
                    />
                    <path
                      id="Rectangle 1197"
                      d="M15.4609 34.1774C15.4609 33.0728 16.3564 32.1774 17.4609 32.1774H24.5387C25.6433 32.1774 26.5387 33.0728 26.5387 34.1774V35.1698H15.4609V34.1774Z"
                      fill="#FBCD57"
                    />
                    <path
                      id="Rectangle 1"
                      d="M30.9426 15.0562C30.9426 20.964 26.7232 25.7533 21.5184 25.7533L20.4811 25.7533C15.2763 25.7533 11.0569 20.964 11.0569 15.0562L11.0569 10.0331C11.0569 8.11593 10.5174 6.24892 9.51707 4.70422C8.90141 3.75355 9.49946 2.41206 10.5389 2.41206L31.4606 2.41206C32.5 2.41206 33.0981 3.75355 32.4824 4.70422C31.4821 6.24892 30.9426 8.11593 30.9426 10.0331L30.9426 15.0562Z"
                      fill="#FBCD57"
                    />
                    <path
                      id="Union"
                      opacity="0.82"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M23.0506 9.79321C21.108 9.79321 19.705 11.1962 19.705 12.9229V19.2903H21.9713V12.9229C21.9713 12.3269 22.4545 11.8437 23.0506 11.8437H25.8565V9.79321H23.0506ZM16.1436 9.79322H21.225C20.5369 9.89908 19.637 10.9048 19.3812 11.8437H16.1436V9.79322Z"
                      fill="#F49B14"
                    />
                    <path
                      id="Rectangle 1198"
                      d="M14.0638 35.8539C14.0638 34.7493 14.9593 33.8539 16.0638 33.8539H25.9363C27.0409 33.8539 27.9363 34.7493 27.9363 35.8539V38.5879C27.9363 39.1402 27.4886 39.5879 26.9363 39.5879H15.0638C14.5116 39.5879 14.0638 39.1402 14.0638 38.5879V35.8539Z"
                      fill="#F3A221"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div className={s.content}>
              <span>지금 내가 있는 곳의</span>
              <br />
              <span>
                <em>TOP 50 인기 장소</em>는 어디?
              </span>
            </div>
          </div>
          <div>
            <svg
              width="7"
              height="13"
              viewBox="0 0 7 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="layer">
                <path
                  id="Vector"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.59949 6.12855L0.726718 0.125L0 0.867901L5.50941 6.5L0 12.1321L0.726718 12.875L6.59949 6.87145C6.80017 6.66631 6.80017 6.3337 6.59949 6.12855Z"
                  fill="#6E747A"
                />
              </g>
            </svg>
          </div>
        </div>
      </PlaceCategoryLink>
    </div>
  );
};

export default RankingStaticBanner;
