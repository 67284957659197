import {useCallback} from 'react';
import {IcoCheckBold} from 'components/@tmds/icons/IcoCheckBold';
import {IcoViewListBold} from 'components/@tmds/icons/IcoViewListBold';
import {IcoViewGridBold} from 'components/@tmds/icons/IcoViewGridBold';
import {ReactComponent as IconDivider} from 'resource/images/divider.svg';
import classNames from 'classnames';
import {ESortOption} from 'types/Search';
import {EViewMode} from 'pages/RankingPage';
import {useDispatch} from 'react-redux';
import actions from 'ducks/actions';
import {useAppSelector} from 'ducks/hooks';

import s from 'styles/components/search/SearchRankingListHeader.module.scss';

const SearchRankingListHeader = ({viewMode, onClickViewMode}) => {
  const sortOption = useAppSelector((state) => state.tRank.activeSortOption);
  const dispatch = useDispatch();

  const handleClickSortOption = useCallback(
    (key) => {
      dispatch(actions.tRank.toggleSortOption(key));
    },
    [dispatch]
  );

  const handleClickViewMode = useCallback(
    (key) => {
      onClickViewMode(key);
    },
    [onClickViewMode]
  );

  return (
    <div className={s.wrap}>
      <div className={s.filter}>
        <div
          className={classNames(s.item, {
            [s.clicked]: sortOption[ESortOption.SCORE],
          })}
          onClick={() => handleClickSortOption(ESortOption.SCORE)}
        >
          <IcoCheckBold width={16} height={16} color="gray300" />
          <span>지금 가는순</span>
        </div>
        <div
          className={classNames(s.item, {
            [s.clicked]: sortOption[ESortOption.DISTANCE],
          })}
          onClick={() => handleClickSortOption(ESortOption.DISTANCE)}
        >
          <IcoCheckBold width={16} height={16} color="gray300" />
          <span>거리순</span>
        </div>
      </div>
      <div className={s.mode}>
        <div className={s.list} onClick={() => handleClickViewMode(EViewMode.RANKING)}>
          <IcoViewListBold
            width={15}
            height={15}
            color={viewMode === EViewMode.RANKING ? 'gray900' : 'gray300'}
          />
        </div>
        <div className={s.divider}>
          <IconDivider />
        </div>
        <div className={s.grid} onClick={() => handleClickViewMode(EViewMode.PHOTO)}>
          <IcoViewGridBold
            width={15}
            height={15}
            color={viewMode === EViewMode.PHOTO ? 'gray900' : 'gray300'}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchRankingListHeader;
