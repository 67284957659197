import {useCallback, useEffect, useMemo, useRef} from 'react';
import classNames from 'classnames';
import actions from 'ducks/actions';
import {fetchSearchList} from 'ducks/search/slice';
import {EButtonType} from 'types/Button';
import {ESortOption} from 'types/Search';
import {ESkeletonType, EAppExtraCode, EDetailButtonType, EAppRequestMode} from 'types/App';
import {TActionId} from 'types/Log';
import {
  DEFAULT_HEADER_HEIGHT,
  HEADER_QUICK_LINK_MARGIN,
  SEARCH_RESULT_BANNER_SIZE,
  SEARCH_RESULT_CENTER_MAP_PERCENT_SIZE,
  SEARCH_RESULT_LIST_HANDLE_HEIGHT,
  TITLE_AREA_HEIGHT,
} from 'constant/Size';
import {ETempWindowKey} from 'constant/Storage';
import {EPageType, ETLAServiceId, TLA_SEARCH_NO_RESULT, TLA_SEARCH_RESULT} from 'constant/Log';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {useAppLocation} from 'hooks/useAppLocation';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import useRecentQuery from 'hooks/useRecentQuery';
import useMapOffset from 'hooks/useMapOffset';
import useLogger from 'hooks/useLogger';
import {useParamLog} from 'hooks/useParamLog';
import useSearchResult from 'hooks/useSearchResult';
import useMoveToTarget from 'hooks/useMoveToTarget';
import {VSMInterfaceProvider} from 'context/VSMInterfaceContext';
import {init as initLog} from 'utils/logManager';
import {afSendSearchResultPageLog} from 'utils/appsFlyer';
import {getAppActionButton} from 'utils/tmapUtils';
import Header from 'components/Header';
import VSMCompass from 'components/VSMCompass';
import DrawerContainer from 'components/DrawerContainer';
import SearchResultMap from 'components/search/SearchResultMap';
import SearchResultHeader, {TSearchResultSortParam} from 'components/search/SearchResultHeader';
import RouteAddPopup from 'components/RouteAddPopup';
import Skeleton from 'components/Skeleton';
import {PlaceCategoryLink} from 'components/PlaceCategoryLink';
import SearchResultList from 'components/search/SearchResultList';
import {AdBanner} from 'components/AdBanner';
import SearchGuide from 'components/search/SearchGuide';
import {ReloadResume} from 'components/ReloadResume';
import SearchRecommendList from 'components/search/SearchRecommendList';
import {CATEGORY_SHORT_ADDRESS_KEY, CATEGORY_SHORT_ADDRESS_VALUE} from 'constant/Poi';
import {ECategoryType, ESearchCollectionType} from 'ducks/search/types';
import {
  EAdCode,
  SEARCH_BANNER_AD_STEP,
  SEARCH_RESULT_BANNER_PROVIDER_CONFIG,
  TEST_BANNER_PROVIDER_CONFIG,
  isBannerTestEnv,
} from 'constant/Ads';
import useUserPublicTransData from 'hooks/useUserPublicTransData';
import SearchRecommendHeader from 'components/search/SearchRecommendHeader';
import SearchResultSortPoi from 'components/search/SearchResultSortPoi';
import SearchTmapAI from 'components/search/SearchTmapAI';

import s from 'styles/pages/SearchResultPage.module.scss';

type TProps = {
  hide?: boolean;
};

const sortActionIdMap: Record<string, TActionId> = {
  [ESortOption.DISTANCE]: 'sort_tap.dist',
  [ESortOption.SCORE]: 'sort_tap.score',
};

const DEFAULT_HEADER_LIST = [
  ESearchCollectionType.POI,
  ESearchCollectionType.BUS_LINE,
  ESearchCollectionType.BUS_STATION,
];
// 즐겨찾기로 인입했을 때 버스 탭 제거 - 버스를 즐겨찾기 장소로 지정할 수 없기 때문
const EXTRA_HEADER_LIST = [ESearchCollectionType.POI, ESearchCollectionType.BUS_STATION];

const SearchResultPage = ({hide}: TProps) => {
  const dispatch = useAppDispatch();
  const {goBack} = useAppLocation();
  const {getBoundsPaddingAndOffset, centerOffset, getMapOffset} = useMapOffset();
  const {
    sendSearchPageLog,
    sendClickLog,
    sendClickLogWithMapView,
    sendSearchResultLog,
    sendSearchNoResultLog,
  } = useLogger();

  const {queries, originQueries} = useParseQueryLocation();
  const {
    isHybrid,
    userInteraction,
    userPosition,
    suggestSearchType,
    windowSize,
    nowCenter,
    userInfo,
    loadedFirstBanner,
    statusBarHeight,
    searchBarHeight,
    bypassCustomItems,
    isLogInitialized,
    remoteConfig,
    remoteLastUpdate,
    collectionType,
    prevGuideSearchType,
  } = useAppSelector((state) => ({
    isHybrid: state.layout.isHybrid,
    userInteraction: state.userInteraction,
    userPosition: state.map.userPosition,
    suggestSearchType: state.search.data.poi?.suggestSearchType,
    drawerMode: state.userInteraction.drawerMode,
    windowSize: state.layout.windowSize,
    nowCenter: state.map.nowCenter,
    userInfo: state.userInfo,
    loadedFirstBanner: state.layout.loadedFirstBanner,
    statusBarHeight: state.layout.appSize.statusBarHeight,
    searchBarHeight: state.layout.appSize.searchBarHeight,
    bypassCustomItems: state.log.bypassCustomItems,
    isLogInitialized: state.log.isInitialize,
    remoteConfig: state.remote,
    remoteLastUpdate: state.remote.lastUpdateTime || 0,
    collectionType: state.search.data.collectionType,
    prevGuideSearchType: state.search.prevGuideSearchType,
  }));

  const {
    searchList,
    rdSearch,
    needSaveQuery,
    nowPage,
    totalCount,
    isLoaded,
    isLoading,
    isLastPage,
    isError,
  } = useSearchResult();
  const {reqMode, reqType, extra} = useMoveToTarget();

  const focusTargetRef = useRef<HTMLInputElement>(null);
  const {referrer} = useParamLog();
  const isCoordReady = useMemo(() => {
    return !!(userPosition?.lat && userPosition?.lon && nowCenter?.lat && nowCenter.lon);
  }, [nowCenter?.lat, nowCenter?.lon, userPosition?.lat, userPosition?.lon]);

  const {addItem: addRecentQuery} = useRecentQuery();
  const actionButtonType = getAppActionButton({reqMode, reqType, extra});
  const isAdVisible = collectionType === ESearchCollectionType.POI;
  const isHideBusLine = actionButtonType === EDetailButtonType.CONFIRM;
  const isEventTab = reqMode === EAppRequestMode.EVENT_PAGE;

  const visibleTNowLanding = useMemo(() => {
    return (
      getAppActionButton({
        extra: originQueries.extra,
        reqMode: originQueries.reqMode,
        reqType: originQueries.reqType,
      }) === EDetailButtonType.DESTINATION && searchList?.length > 0
    );
  }, [originQueries.extra, originQueries.reqMode, originQueries.reqType, searchList?.length]);

  const hybridMarginTop = useMemo(
    () =>
      isHybrid
        ? searchBarHeight + statusBarHeight + HEADER_QUICK_LINK_MARGIN
        : DEFAULT_HEADER_HEIGHT + HEADER_QUICK_LINK_MARGIN,
    [isHybrid, searchBarHeight, statusBarHeight]
  );

  const isSearchRecommend = useMemo(() => rdSearch.data?.ltrYn, [rdSearch.data?.ltrYn]);

  const {init: initPublicTransData} = useUserPublicTransData();

  useEffect(() => {
    if (needSaveQuery) {
      addRecentQuery(queries.searchQuery);
    }
  }, [needSaveQuery, addRecentQuery, queries.searchQuery]);

  useEffect(() => {
    if (!queries.searchQuery) {
      dispatch(actions.userInfo.resetSearchSessionKey());
    }
  }, [dispatch, queries.searchQuery]);

  const getList = useCallback(
    (pagingParam = {}) => {
      dispatch(actions.userInfo.setSearchSessionKey());
      dispatch(
        fetchSearchList({
          query: queries.searchQuery,
          // TODO: categories eventTab이랑 같이 확인 필요
          categories: isEventTab ? [ECategoryType.POI_EVENT] : null,
          ...pagingParam,
        })
      );
    },
    [dispatch, queries.searchQuery, isEventTab]
  );

  useEffect(() => {
    if (hide) {
      // 화면에서 사라질때 작업
      dispatch(actions.search.resetList());
      dispatch(actions.search.resetSort());
      dispatch(actions.userInteraction.resetListInteraction());
      dispatch(
        actions.search.updateFilter({
          tmapFamousYn: false,
          poiParkYn: false,
          openNowYn: false,
          isWaitingReservation: false,
        })
      );
      dispatch(
        actions.search.updateCategories({
          categories: [],
        })
      );

      // 첫 진입 관련 state 초기화
      dispatch(actions.log.addBypassCustomItems({}));
      dispatch(actions.log.setInitialize(false));
    }

    return () => {
      const goBackToMain = !hide;

      if (!window[ETempWindowKey.NOW_QUERY] && goBackToMain) {
        dispatch(actions.search.setAddressSearch(null));
      }
    };
  }, [dispatch, hide]);

  useEffect(() => {
    // 현재페이지로 들어올때 작업
    if (!hide && isCoordReady) {
      getList({
        collectionType: isHideBusLine ? ESearchCollectionType.POI : undefined,
      });
      initPublicTransData();
    }
  }, [getList, hide, isCoordReady]);

  useEffect(() => {
    if (hide || !isLoaded || nowPage !== 1) {
      return;
    }

    dispatch(actions.log.addBypassCustomItems({app: {}}));
    initLog({
      sessionId: userInfo.sessionId,
      accessKey: userInfo.accessKey,
      sessionKey: userInfo.sessionKey,
      userKey: userInfo.userKey,
      deviceId: userInfo.device.deviceId,
      carrierName: userInfo.device.carrierName,
      pageId: searchList.length === 0 ? TLA_SEARCH_NO_RESULT : TLA_SEARCH_RESULT,
      pageType: EPageType.SEARCH,
      referrer,
    });

    sendSearchPageLog({
      search_query: queries.searchQuery,
      // 1 : 첫진입 / 재검색
      index: 1,
    });

    dispatch(actions.log.setInitialize(true));
  }, [hide, isLoaded]);

  useEffect(() => {
    if (!isLogInitialized || !bypassCustomItems.app || !isLoaded) {
      return;
    }

    afSendSearchResultPageLog({
      search_query: queries.searchQuery,
      list_num: searchList.length,
    });

    if (totalCount > 0) {
      sendSearchResultLog({
        search_query: queries.searchQuery,
        search_type: suggestSearchType,
        index: 1,
      });
    } else {
      sendSearchNoResultLog({
        search_query: queries.searchQuery,
        index: 1,
      });
    }
  }, [isLogInitialized, bypassCustomItems.app]);

  useEffect(() => {
    if (!hide) {
      dispatch(actions.log.setSearchQueryRefreshKey(Date.now()));
    }
  }, [dispatch, hide, queries.searchQuery]);

  useEffect(() => {
    if (!userInteraction.resumeKey) {
      return;
    }

    sendSearchPageLog({
      search_query: queries.searchQuery,
      // 0 : 상세나 길찾기 갔다가 돌아왔을 때
      index: 0,
    });
  }, [userInteraction.resumeKey]);

  useEffect(() => {
    if (!userInteraction.resumeKey || !isLoaded) {
      // 재진입 확인용
      return;
    }

    if (totalCount > 0) {
      sendSearchResultLog({
        search_query: queries.searchQuery,
        search_type: suggestSearchType,
        index: 0,
      });
    } else {
      sendSearchNoResultLog({
        search_query: queries.searchQuery,
        index: 0,
      });
    }
  }, [userInteraction.resumeKey]);

  useEffect(() => {
    !hide &&
      dispatch(
        actions.map.setCenterOffset({
          x: centerOffset.x,
          y: centerOffset.y,
        })
      );

    return () => {
      !hide && dispatch(actions.map.setCenterOffset({x: 0, y: 0}));
    };
  }, [hide, centerOffset.x, centerOffset.y, dispatch]);

  const handleChangeOption = useCallback(
    (param: TSearchResultSortParam) => {
      dispatch(
        actions.search.resetList({
          guideSearchType: isSearchRecommend ? prevGuideSearchType : '',
        })
      );
      if (param.sort) {
        getList({usePrevCenter: true, collectionType});
      } else if (param.locationType) {
        getList({locationType: param.locationType, collectionType});
      } else if (typeof param.cityCode !== 'undefined') {
        getList({cityCode: param.cityCode, collectionType});
      }

      if (param.sort) {
        sendClickLog(
          sortActionIdMap[param.sort],
          {
            search_query: queries.searchQuery,
            list_num: searchList.length,
          },
          {includeTicketId: true}
        );
      }
      dispatch(actions.userInteraction.resetListInteraction());
      // focusTargetRef?.current?.focus();
    },
    [
      dispatch,
      getList,
      sendClickLog,
      queries.searchQuery,
      searchList,
      collectionType,
      prevGuideSearchType,
      isSearchRecommend,
    ]
  );

  const handleChangeCollectionType = useCallback(
    (type) => {
      dispatch(actions.search.reset());
      getList({
        collectionType: type,
      });
    },
    [dispatch, getList]
  );

  const handleChangeFilter = useCallback(
    (filter, isOn) => {
      getList({[filter]: isOn});
    },
    [getList]
  );

  const handleChangeCategory = useCallback(
    (categories) => {
      getList({categories});
    },
    [getList]
  );

  const handleClickConfirm = useCallback(
    (filters, categories, filterLog) => {
      sendClickLog('tap.select_filter', {
        option: [...Object.keys(filterLog).filter((key) => filterLog[key]), ...categories].join(
          ','
        ),
      });
      getList({
        ...filters,
        categories,
      });
    },
    [getList, sendClickLog]
  );

  const handleRefresh = useCallback(
    (refreshCenter) => {
      dispatch(
        actions.search.resetList({
          guideSearchType: isSearchRecommend ? prevGuideSearchType : '',
        })
      );
      getList({...refreshCenter, collectionType, onMap: true});
      dispatch(actions.userInteraction.resetListInteraction());
    },
    [dispatch, collectionType, getList, isSearchRecommend, prevGuideSearchType]
  );

  const handleClickResetFilter = useCallback(() => {
    dispatch(
      actions.search.updateFilter({
        ltrYn: true,
        fromRecommendedYn: true,
        tmapFamousYn: false,
        poiParkYn: false,
        openNowYn: false,
        isWaitingReservation: false,
      })
    );
    dispatch(
      actions.search.updateCategories({
        ltrYn: true,
        fromRecommendedYn: true,
        categories: [],
      })
    );
    getList();
  }, [dispatch, getList]);

  const handleTitleClick = useCallback(() => {
    window[ETempWindowKey.NOW_QUERY] = queries.searchQuery;
    sendClickLog('tap.back');
    goBack();
  }, [goBack, queries, sendClickLog]);

  const handleErrorReload = useCallback(() => {
    dispatch(actions.search.resetList());
    getList({collectionType});
    sendClickLogWithMapView('tap.fail_refresh');
  }, [getList, collectionType, dispatch, sendClickLogWithMapView]);

  const handleClickBack = useCallback(() => {
    if (!!queries.fromDeepLink && queries.extra === EAppExtraCode.SEARCH_T_MAP) {
      handleTitleClick();
    } else {
      sendClickLog('tap.back');
      goBack();
    }
  }, [queries, handleTitleClick, sendClickLog, goBack]);

  const handleClickCategoryLink = useCallback(
    (e, type) => {
      sendClickLogWithMapView(
        'tap.near_poi_category',
        {
          search_query: type,
          search_result_query: queries.searchQuery,
        },
        {
          includeTicketId: true,
        }
      );
    },
    [sendClickLogWithMapView, queries]
  );

  // useEffect(() => {
  //   queries.searchQuery && !hide && focusTargetRef?.current?.focus();
  // }, [queries.searchQuery, hide]);

  return loadedFirstBanner || !hide ? (
    <VSMInterfaceProvider>
      <div
        className={classNames(s.result_page_wrap, {
          [s.hide_page]: hide,
          [s.hybrid_page]: isHybrid,
        })}
        aria-hidden={hide}
      >
        <input
          ref={focusTargetRef}
          role="document"
          aria-label={`${queries.searchQuery} 검색결과`}
          className={s.focus_label}
        />
        <div className={s.header}>
          {!isHybrid && (
            <Header
              leftButton={EButtonType.BACK}
              rightButton={EButtonType.CLOSE}
              onGoBack={handleClickBack}
            >
              <div className={s.title} onClick={handleTitleClick}>
                {queries.searchQuery || '검색어 없음'}
              </div>
            </Header>
          )}
          {reqMode !== EAppRequestMode.EVENT_PAGE && (
            <div
              className={s.top_actions}
              style={hybridMarginTop ? {marginTop: hybridMarginTop} : {}}
            >
              {!isError && (
                <Skeleton
                  type={ESkeletonType.SEARCH_QUICK_LINK}
                  apiStatus={{
                    ...rdSearch,
                    loading: isLoading || remoteLastUpdate < 1,
                    loaded: isLoaded && remoteLastUpdate > 0,
                  }}
                >
                  <div className={s.place_link_list}>
                    {remoteConfig.searchResultCategoryLink.map(({type, ...c}, i) => (
                      <PlaceCategoryLink
                        key={`${type}_${i}`}
                        className={s.item}
                        type={type}
                        queries={{
                          tailParam: {
                            [CATEGORY_SHORT_ADDRESS_KEY]: CATEGORY_SHORT_ADDRESS_VALUE,
                            log_all_referrer: ETLAServiceId.SEARCH,
                          },
                        }}
                        onClick={(e) => handleClickCategoryLink(e, type)}
                      >
                        {c.label && c.icon && <img alt={c.label} className={s.icon} src={c.icon} />}
                        <span>{c.label}</span>
                      </PlaceCategoryLink>
                    ))}
                  </div>
                </Skeleton>
              )}

              {isLoaded && <VSMCompass className={s.vsm_compass} />}
            </div>
          )}
        </div>

        <DrawerContainer
          isHideToTop={true}
          showFixedTopBtn={true}
          isLastPage={isLastPage}
          visibleTNowLanding={visibleTNowLanding}
          onRefresh={handleRefresh}
          isStaticRefreshLabel={true}
          list={searchList}
          listMode={userInteraction.drawerMode}
          onChangeListMode={(mode) => {
            dispatch(actions.userInteraction.setInteraction({drawerMode: mode}));
          }}
          mapComponent={(drawerProps) => {
            const mapOffset = getMapOffset(drawerProps);
            const {boundsPadding} = getBoundsPaddingAndOffset(drawerProps, {
              headerHeight: isHybrid ? 0 : searchBarHeight,
            });

            return (nowCenter || userPosition) && boundsPadding && !hide ? (
              <SearchResultMap
                boundsPadding={boundsPadding}
                centerOffset={centerOffset}
                mapOffset={mapOffset}
                centerToCurrentPosition={collectionType === ESearchCollectionType.BUS_LINE}
              />
            ) : (
              <></>
            );
          }}
          extraMargin={SEARCH_RESULT_BANNER_SIZE}
          listHeaderComponent={
            <Skeleton type={ESkeletonType.SEARCH_HEADER} apiStatus={rdSearch}>
              {isSearchRecommend ? (
                <>
                  <SearchRecommendHeader
                    onChange={handleChangeFilter}
                    onChangeCategory={handleChangeCategory}
                    onClickConfirm={handleClickConfirm}
                  />
                </>
              ) : (
                <SearchResultHeader
                  pageHide={hide}
                  collectionList={isHideBusLine ? EXTRA_HEADER_LIST : DEFAULT_HEADER_LIST}
                  onChange={handleChangeOption}
                  onChangeCollectionType={handleChangeCollectionType}
                />
              )}
            </Skeleton>
          }
          listComponent={
            <Skeleton type={ESkeletonType.POI_MAIN_LIST} apiStatus={rdSearch}>
              {isAdVisible && (
                <div className={s.banner_wrap}>
                  <ReloadResume active={!hide}>
                    <AdBanner
                      isLogInitialized={isLogInitialized}
                      visibleLandscape={true}
                      adCode={EAdCode.SEARCH_RESULT}
                      adTypeStep={SEARCH_BANNER_AD_STEP}
                      adTypeOption={{
                        ...SEARCH_RESULT_BANNER_PROVIDER_CONFIG,
                        ...(isBannerTestEnv ? TEST_BANNER_PROVIDER_CONFIG : {}),
                      }}
                      logData={{
                        custom: {search_query: queries.searchQuery},
                        includeTicketId: true,
                      }}
                    />
                  </ReloadResume>
                </div>
              )}

              {isSearchRecommend ? (
                <>
                  <div className={s.sort_wrap}>
                    <SearchResultSortPoi onChange={handleChangeOption} />
                  </div>
                  <div className={s.tmap_ai_wrap}>
                    <SearchTmapAI
                      tooltipContents={
                        <div>
                          주행 데이터 및 패턴분석 기술을
                          <br />
                          통해 인기장소를 추천해 드립니다.
                        </div>
                      }
                    />
                  </div>
                  <SearchRecommendList
                    onReload={handleErrorReload}
                    onReset={handleClickResetFilter}
                  />
                </>
              ) : (
                <SearchResultList onReload={handleErrorReload} />
              )}
            </Skeleton>
          }
          drawerOptions={{
            centerHeight: parseInt(
              `${(windowSize.height * SEARCH_RESULT_CENTER_MAP_PERCENT_SIZE) / 100}`,
              10
            ),
            topAreaHeight:
              (isHybrid ? searchBarHeight : TITLE_AREA_HEIGHT) - HEADER_QUICK_LINK_MARGIN,
            listHandleHeight: SEARCH_RESULT_LIST_HANDLE_HEIGHT,
          }}
          statusBarHeight={isHybrid ? statusBarHeight : undefined}
          paddingTop={hybridMarginTop}
        />

        <SearchGuide />
        {!hide && <RouteAddPopup />}
      </div>
    </VSMInterfaceProvider>
  ) : null;
};

export default SearchResultPage;
