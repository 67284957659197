import BottomSheetPopup from 'components/tplacehome/shared/BottomSheetPopup';
import BottomSheetPopupButtonSet from 'components/tplacehome/shared/BottomSheetPopupButtonSet';
import s from 'styles/components/tplacehome/TPlacePopupBase.module.scss';
import RegionSelectButton from './RegionSelectButton';
import {useMemo} from 'react';

type TProps = {
  isOpen: boolean;
  name1: string;
  name2: string;
  onClickDepthButton: (depth: 1 | 2) => void;
  onClickReset: VoidFunction;
  onClickSubmit: VoidFunction;
  name2Disabled?: boolean;
};

const RegionMainPopup = ({
  onClickDepthButton,
  onClickReset,
  onClickSubmit,
  isOpen,
  name1,
  name2,
  name2Disabled,
}: TProps) => {
  const name2Label = useMemo(() => {
    if (name2Disabled) {
      return '선택 없음';
    }
    return name2 || '선택';
  }, [name2, name2Disabled]);

  return (
    <BottomSheetPopup
      isOpen={isOpen}
      contentComponent={
        <div className={s.content_wrap}>
          <div className={s.button_group}>
            <div className={s.title}>지역변경</div>
            <div className={s.region_button_wrap}>
              <RegionSelectButton
                name={name1}
                onClick={() => {
                  onClickDepthButton(1);
                }}
              />
              <RegionSelectButton
                name={name2Label}
                onClick={() => {
                  onClickDepthButton(2);
                }}
                disabled={name2Disabled}
              />
            </div>
          </div>
        </div>
      }
      bottomComponent={
        <BottomSheetPopupButtonSet
          onClickReset={onClickReset}
          onClickConfirm={onClickSubmit}
          confirmText="변경 완료"
        />
      }
    />
  );
};

export default RegionMainPopup;
