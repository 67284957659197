import {CSSProperties, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import InView from 'react-intersection-observer';
import TMapSender from '@lcc/tmap-inapp';
import classNames from 'classnames';
import {datadogLogs} from '@datadog/browser-logs';

import {TBannerDebugInfo} from 'types/Ads';
import {TLonLat} from 'types/Map';
import {TAdsItem} from 'hooks/useTMapAds';

import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';

import {
  AD_BANNER_MAX_LOAD_TIME,
  AD_SOURCE_MAP,
  EAdCode,
  EAdType,
  EAsumAdUnitId,
  EMolocoAdUnit,
  ENaverDaUnitId,
  ETMapBannerCode,
} from 'constant/Ads';

import useLogger from 'hooks/useLogger';
import {useOnce} from 'hooks/useOnce';
import useThrottle from 'hooks/useThrottle';

import {AdStepper} from 'utils/ads';
import {devLog} from 'utils/dev';

import TMapInternalBanner from 'modules/TMapInternalBanner';
import AsumBanner from 'modules/AsumBanner';
import MolocoBanner from 'modules/MolocoBanner';
import NaverBanner from 'modules/NaverBanner';

import BannerInfo, {getDevAdItem} from 'components/BannerInfo';

import NoneAltBanner from 'resource/pubImages/mainbanner.png';

import s from 'styles/components/AdBanner.module.scss';

export type TAdOption = {
  inner: {
    inventoryCode: ETMapBannerCode;
  };
  moloco?: {
    adUnitId: EMolocoAdUnit;
  };
  naver?: {
    adUnitId: ENaverDaUnitId;
  };
  asum?: {
    adUnitId: EAsumAdUnitId;
  };
  default?: {
    imageUrl: string;
    landingLink: string;
  };
};

export type TProps = {
  adCode: EAdCode;
  adTypeStep: EAdType[];
  adTypeOption?: TAdOption;

  visibleLandscape?: boolean;
  logData?: {
    custom?: Record<string, any>;
    includeTicketId?: boolean;
  } & Record<string, any>;
  isLogInitialized?: boolean;
  disableCoords?: boolean;
  bannerLonLat?: TLonLat;
  bannerHeight?: number;
};

type TBannerStatus = {
  type?: EAdType;
  startTime?: number;
  endTime?: number;
  isSuccess?: boolean;
  fromTimer?: boolean;
  errorMessage?: string;
};

const ALWAYS_SHOW_BANNER = true;
const DEFAULT_BANNER_HEIGHT = 56;

export const AdBanner = ({
  visibleLandscape,
  adCode,
  adTypeOption,
  adTypeStep: originAdStep,
  isLogInitialized = true,
  logData,
  disableCoords = false,
  bannerLonLat,
  bannerHeight = DEFAULT_BANNER_HEIGHT,
}: TProps) => {
  const dispatch = useAppDispatch();
  const ableToRun = useThrottle();
  const adTypeStep = useMemo(() => getDevAdItem() || originAdStep, [originAdStep]);

  const {isLandscape, accessKey, nowCenter} = useAppSelector((state) => ({
    isLandscape: state.layout.appSize.isLandscape,
    accessKey: state.userInfo.accessKey,
    nowCenter: state.map.nowCenter,
  }));
  const [extraLogCallTime, setExtraLogCallTime] = useState(0);
  const refPrevRotate = useRef(isLandscape);
  const {sendClickLog, sendApp} = useLogger();

  const [moduleStartTime, setModuleStartTime] = useState<number>(0);
  const [debugInfo, setDebugInfo] = useState<TBannerDebugInfo[]>();

  const [nowAd, setNowAd] = useState<EAdType>();
  const refNowAd = useRef<EAdType>(EAdType.NONE);

  const refAdStepper = useRef<Generator<EAdType>>();
  const refTimerId = useRef<number>();

  const refUnsentLogQueue = useRef<object[]>([]);

  const refBannerStatus = useRef<Record<string, TBannerStatus>>({});
  const refTotalDuration = useRef<number>(0);

  const refIsImpressionLogSent = useRef<boolean>(false);
  const refIsObserved = useRef<boolean>(false);

  const [innerData, setInnerData] = useState<TAdsItem>();
  const [finalBanner, setFinalBanner] = useState<EAdType>();

  useOnce(
    accessKey &&
      (disableCoords || nowCenter) &&
      (visibleLandscape ? ALWAYS_SHOW_BANNER : !isLandscape),
    () => {
      refAdStepper.current = AdStepper(adTypeStep);
      devLog('[Banner Start]', EAdCode[adCode]);

      setModuleStartTime(Date.now());

      moveNext();
    }
  );

  useEffect(() => {
    if (isLandscape && !visibleLandscape) {
      setExtraLogCallTime(0);
      refPrevRotate.current = isLandscape;
      return;
    }
    if (refPrevRotate.current !== isLandscape) {
      setExtraLogCallTime(Date.now());
    }
    refPrevRotate.current = isLandscape;
  }, [isLandscape, visibleLandscape]);

  const sendAppLog = useCallback(
    (from?: EAdType) => {
      const type = from || refNowAd.current;
      const currentExpireItem = refBannerStatus.current[type];

      if (currentExpireItem && currentExpireItem.startTime && currentExpireItem.endTime) {
        const duration = (currentExpireItem.endTime || 0) - (currentExpireItem.startTime || 0);
        const totalDuration = refTotalDuration.current + duration;
        const stepIndex = adTypeStep.findIndex((a) => a === type);
        const error = currentExpireItem.errorMessage || '';

        const data = {
          ad_source: AD_SOURCE_MAP[type],
          success: currentExpireItem.isSuccess ? true : false,
          ad_source_loading_duration: duration,
          all_loading_duration: totalDuration,
          timeout: currentExpireItem.fromTimer ? true : false,
          step: stepIndex === -1 ? adTypeStep.length + 1 : stepIndex + 1,
          index: adCode,
          error,
        };

        if (isLogInitialized) {
          sendApp('', data);
          sendClickLog('log.ad', data);
        } else {
          refUnsentLogQueue.current.push(data);
        }

        devLog('[Banner SendAppLog]', EAdCode[adCode], type, data, currentExpireItem);

        refTotalDuration.current = totalDuration;
        refBannerStatus.current[type] = {};
      }
    },
    [adCode, adTypeStep, isLogInitialized, sendApp, sendClickLog]
  );

  const updateEndLog = useCallback(
    ({type, isSuccess, fromTimer = false, errorMessage}: TBannerStatus) => {
      if (type && refBannerStatus.current[type]) {
        refBannerStatus.current[type].isSuccess = isSuccess;
        refBannerStatus.current[type].endTime = Date.now();
        refBannerStatus.current[type].fromTimer = fromTimer;
        refBannerStatus.current[type].errorMessage = errorMessage;
      }
    },
    []
  );

  const expireTimer = useCallback(
    (from?: EAdType) => {
      if (from && from !== refNowAd.current) {
        return;
      }

      if (refTimerId.current) {
        sendAppLog(from);
        window.clearTimeout(refTimerId.current);
        refTimerId.current = undefined;
      }
    },
    [sendAppLog]
  );

  const moveNext = useCallback(
    (from?: EAdType) => {
      if (from && from !== refNowAd.current) {
        return;
      }

      expireTimer();

      const nextItem = refAdStepper.current?.next();
      const nextValue = nextItem?.value || EAdType.NONE;

      devLog('[Banner MoveNext]', EAdCode[adCode], nextValue);

      refBannerStatus.current[nextValue] = {};
      refBannerStatus.current[nextValue].startTime = Date.now();

      refNowAd.current = nextValue;

      if (nextValue) {
        setDebugInfo((prev) => {
          const next = [...(prev || [])];

          next.push({
            type: nextValue,
            setTime: Date.now(),
          });

          return next;
        });
      }

      if (nextValue !== EAdType.NONE) {
        refTimerId.current = window.setTimeout(() => {
          devLog('[Banner TimeOut]', EAdCode[adCode]);

          setDebugInfo((prev) =>
            // eslint-disable-next-line max-nested-callbacks
            prev?.map((n) => {
              if (n.type === nextValue) {
                return {
                  ...n,
                  timeoutTime: Date.now(),
                };
              }
              return n;
            })
          );

          updateEndLog({
            type: nextValue,
            isSuccess: false,
            fromTimer: true,
          });
          // setFinalBanner(nextValue);
          moveNext(nextValue);
        }, AD_BANNER_MAX_LOAD_TIME);
      }

      if (nextValue === EAdType.NONE) {
        updateEndLog({
          type: EAdType.NONE,
          isSuccess: true,
        });
        setFinalBanner(EAdType.NONE);
        sendAppLog(EAdType.NONE);
      }

      setNowAd(nextValue);
    },
    [adCode, expireTimer, sendAppLog, updateEndLog]
  );

  const sendBannerClickLog = useCallback(
    (adType: EAdType, url = '', opt?: object) => {
      sendClickLog(
        'tap.ad',
        {
          index: adCode,
          ad_source: AD_SOURCE_MAP[adType],
          url,
          ...(opt || {}),
          ...(logData?.custom || {}),
        },
        {includeTicketId: !!logData?.includeTicketId}
      );
    },
    [adCode, logData?.custom, logData?.includeTicketId, sendClickLog]
  );

  const handleBannerStart = useCallback((type: EAdType) => {
    setDebugInfo((prev) =>
      // eslint-disable-next-line max-nested-callbacks
      prev?.map((n) => {
        if (n.type === type) {
          return {
            ...n,
            startTime: Date.now(),
          };
        }
        return n;
      })
    );
  }, []);

  const handleBannerShow = useCallback(
    (type: EAdType) => {
      setDebugInfo((prev) =>
        // eslint-disable-next-line max-nested-callbacks
        prev?.map((n) => {
          if (n.type === type) {
            return {
              ...n,
              showTime: Date.now(),
            };
          }
          return n;
        })
      );

      updateEndLog({
        type,
        isSuccess: true,
      });
      expireTimer(type);
      setFinalBanner(type);
    },
    [expireTimer, updateEndLog]
  );

  const handleBannerError = useCallback(
    (type: EAdType, error) => {
      setDebugInfo((prev) =>
        // eslint-disable-next-line max-nested-callbacks
        prev?.map((n) => {
          if (n.type === type) {
            return {
              ...n,
              errorTime: Date.now(),
            };
          }
          return n;
        })
      );

      devLog('[Banner Error]', EAdCode[adCode], type, error?.message || error);
      updateEndLog({
        type,
        isSuccess: false,
        errorMessage: error?.message || '',
      });
      moveNext(type);
    },
    [adCode, updateEndLog, moveNext]
  );

  const handleClickInnerBanner = useCallback(
    (url: string, data?: TAdsItem) => {
      if (!ableToRun()) {
        return;
      }

      sendBannerClickLog(EAdType.INTERNAL, url, {
        unit: data?.originAd.adId || null,
      });
    },
    [ableToRun, sendBannerClickLog]
  );

  const handleClickAsumBanner = useCallback(
    (url?: string) => {
      if (!ableToRun()) {
        return;
      }

      if (url) {
        sendBannerClickLog(EAdType.ASUM, url);
        TMapSender.openBrowser(url);
      } else {
        datadogLogs.logger.info('[AsumBanner] undefined url in handleClick.');
      }
    },
    [sendBannerClickLog, ableToRun]
  );

  const handleClickMolocoBanner = useCallback(
    (url?: string) => {
      if (!ableToRun()) {
        return;
      }

      if (url) {
        sendBannerClickLog(EAdType.MOLOCO, url);
        TMapSender.openBrowser(url);
      } else {
        datadogLogs.logger.info('[MolocoBanner] undefined url in handleClick.');
      }
    },
    [sendBannerClickLog, ableToRun]
  );

  const handleClickDefaultBanner = useCallback(() => {
    if (!ableToRun()) {
      return;
    }

    const url = adTypeOption?.default?.landingLink;

    if (url) {
      sendBannerClickLog(EAdType.DEFAULT, url);
      TMapSender.openBrowser(url);
    } else {
      datadogLogs.logger.info('[DefaultBanner] undefined url in handleClick.');
    }
  }, [sendBannerClickLog, ableToRun, adTypeOption]);

  const handleClickNaverBanner = useCallback(
    (url?: string) => {
      if (!ableToRun()) {
        return;
      }

      if (url) {
        sendBannerClickLog(EAdType.NAVER, url);
        TMapSender.openBrowser(url);
      } else {
        datadogLogs.logger.info('[NaverBanner] undefined url in handleClick.');
      }
    },
    [sendBannerClickLog, ableToRun]
  );

  const sendImpressionLog = useCallback(() => {
    // 배너 노출 시 클릭 로그
    if (!finalBanner) {
      return;
    }

    const urlMap = {
      [EAdType.INTERNAL]: innerData?.landingUrl || '',
    };

    if (finalBanner === EAdType.INTERNAL && !innerData) {
      return;
    }

    refIsImpressionLogSent.current = true;
    sendClickLog(
      'view.ad',
      {
        index: adCode,
        unit: finalBanner === EAdType.INTERNAL ? innerData?.originAd.adId || null : null,
        ad_source: AD_SOURCE_MAP[finalBanner] || finalBanner,
        url: urlMap[finalBanner] || '',
        ...(logData?.custom || {}),
      },
      {includeTicketId: !!logData?.includeTicketId}
    );
  }, [finalBanner, innerData, adCode, logData, sendClickLog]);

  useOnce(finalBanner, () => {
    dispatch(actions.layout.setLoadedFirstBanner(true));
  });

  useOnce(isLogInitialized, () => {
    refUnsentLogQueue.current.forEach((d) => {
      sendApp('', d);
    });

    refUnsentLogQueue.current = [];
  });

  useOnce(
    isLogInitialized && finalBanner && !refIsImpressionLogSent.current && refIsObserved.current,
    () => {
      sendImpressionLog();
    }
  );

  useEffect(() => {
    return () => {
      devLog('[Banner cleanUp]', EAdCode[adCode]);

      window.clearTimeout(refTimerId.current);
      refTimerId.current = undefined;
      refBannerStatus.current = {};
      refTotalDuration.current = 0;
      refAdStepper.current = undefined;
    };
  }, []);

  return (
    <div
      className={classNames(s.banner, {[s.hide]: !visibleLandscape && isLandscape})}
      aria-hidden={!visibleLandscape && isLandscape}
      aria-label="광고"
      style={
        {
          '--banner-height': `${bannerHeight}px`,
        } as CSSProperties
      }
    >
      <InView
        className={s.banner_box}
        onChange={(isInView) => {
          if (isInView) {
            refIsObserved.current = true;
            !refIsImpressionLogSent.current && sendImpressionLog();
          }
        }}
      >
        {nowAd === EAdType.INTERNAL && adTypeOption?.inner.inventoryCode && (
          <TMapInternalBanner
            inventoryCode={adTypeOption?.inner.inventoryCode}
            onStart={() => handleBannerStart(EAdType.INTERNAL)}
            onShow={(data) => {
              setInnerData(data);
              handleBannerShow(EAdType.INTERNAL);
            }}
            onError={(error) => handleBannerError(EAdType.INTERNAL, error)}
            onClickAd={handleClickInnerBanner}
            extraLogCallTime={extraLogCallTime}
            bannerLonLat={bannerLonLat}
          />
        )}

        <AsumBanner
          isShow={nowAd === EAdType.ASUM}
          onStart={() => handleBannerStart(EAdType.ASUM)}
          onShow={() => handleBannerShow(EAdType.ASUM)}
          onError={(error) => handleBannerError(EAdType.ASUM, error)}
          onClickAd={handleClickAsumBanner}
          adUnitId={adTypeOption?.asum?.adUnitId}
        />

        {nowAd === EAdType.MOLOCO && (
          <MolocoBanner
            onStart={() => handleBannerStart(EAdType.MOLOCO)}
            onShow={() => handleBannerShow(EAdType.MOLOCO)}
            onError={(error) => handleBannerError(EAdType.MOLOCO, error)}
            onClickAd={handleClickMolocoBanner}
            adUnitId={adTypeOption?.moloco?.adUnitId}
          />
        )}

        {nowAd === EAdType.NAVER && (
          <NaverBanner
            onStart={() => handleBannerStart(EAdType.NAVER)}
            onShow={() => handleBannerShow(EAdType.NAVER)}
            onError={(error) => handleBannerError(EAdType.NAVER, error)}
            onClickAd={handleClickNaverBanner}
            adUnitId={adTypeOption?.naver?.adUnitId}
          />
        )}

        {nowAd === EAdType.DEFAULT && adTypeOption?.default?.imageUrl && (
          <div className={s.fallback_banner} onClick={handleClickDefaultBanner}>
            <img
              src={adTypeOption?.default?.imageUrl}
              onLoad={() => handleBannerShow(EAdType.DEFAULT)}
              onError={() => handleBannerError(EAdType.DEFAULT, new Error('source load error'))}
            />
          </div>
        )}

        {nowAd === EAdType.NONE && refAdStepper.current && (
          <div className={s.fallback_banner}>
            <img aria-hidden={true} src={NoneAltBanner} alt="일상의 틈 곳곳에서 TMAP" />
          </div>
        )}
        <BannerInfo
          nowAd={nowAd || '호출준비중'}
          startTime={moduleStartTime}
          infos={debugInfo}
          originAdStep={originAdStep}
        />
      </InView>
    </div>
  );
};
