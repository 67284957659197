import {useCallback, useMemo} from 'react';
import {useAppSelector} from '../../ducks/hooks';
import Flicking from '@egjs/react-flicking';
import {TRankItem} from '../../ducks/rank/types';
import {IcArrowRight} from '../@tmds/icons/v1.2/IcArrowRight';
import SearchPoiItemInfo from './SearchPoiItemInfo';
import useMoveToTarget from '../../hooks/useMoveToTarget';
import {RANKING_UP_DOWN_ICON} from '../ranking/RankingPoiItem';
import Thumbnail from '../Thumbnail';
import SearchMainSection from './SearchMainSection';
import {moveToUrl} from '../../utils/url';
import {usePlaceCategoryLink} from '../../hooks/usePlaceCategoryLink';
import {EPlaceCategoryType} from '../../types/App';
import {sendSearchClickLog} from '../../utils/logManager';
import classNames from 'classnames';
import format from 'date-fns/format';
import {EDateFormat} from '../../types/DateTime';

import s from '../../styles/components/search/SearchRecommendRanking.module.scss';

const SearchRecommendRanking = () => {
  const {rankList, reverseGeoInfo} = useAppSelector((state) => ({
    rankList: state.searchRecommend.rankData.result.data.list,
    reverseGeoInfo: state.searchRecommend.reverseGeoInfo,
  }));

  const {moveToDetail} = useMoveToTarget();
  const url = usePlaceCategoryLink({type: EPlaceCategoryType.RANKING});

  const ranks = useMemo(() => {
    const arr: TRankItem[][] = [];
    for (let i = 0; i < rankList.length; i += 3) {
      arr.push(rankList.slice(i, i + 3));
    }
    return arr;
  }, [rankList]);

  const monday = useMemo(() => {
    const d = new Date(new Date());
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);

    return format(new Date(d.setDate(diff)), EDateFormat.MMdd);
  }, []);

  const handleClick = useCallback(
    (item: TRankItem, rankNum: number) => {
      sendSearchClickLog('tap.tmap_ranking_poi', {
        list_num: rankList.length,
        region_1D: reverseGeoInfo.regionName1,
        region_2D: reverseGeoInfo.regionName2,
        region_3D: reverseGeoInfo.regionName3,
        list_seq: rankNum,
        pkey: item.pkey,
        poicategory: item.categoryCode,
        review_cnt: item.special.reviewCount || null,
        grade: item.special.avgStar || null,
      });
      moveToDetail(item);
    },
    [rankList, reverseGeoInfo, moveToDetail]
  );

  const handleClickMore = useCallback(() => {
    sendSearchClickLog('tap.tmap_ranking_viewall', {
      list_num: rankList.length,
      region_1D: reverseGeoInfo.regionName1,
      region_2D: reverseGeoInfo.regionName2,
      region_3D: reverseGeoInfo.regionName3,
    });
    moveToUrl(url);
  }, [rankList, reverseGeoInfo, url]);

  let rank = 1;

  if (!rankList.length) {
    return null;
  }

  return (
    <SearchMainSection title={'내 주변 티맵랭킹'} handleClick={handleClickMore}>
      <div className={s.wrap}>
        <p className={s.date}>{monday} 업데이트</p>
        <Flicking
          align={{camera: '16px', panel: 0}}
          moveType={['strict', {count: 1}]}
          disableOnInit={rankList.length <= 3}
        >
          {ranks.map((list, index) => {
            return (
              <div key={index} className={classNames(s.panel, {[s.full]: rankList.length <= 3})}>
                <ul className={s.list}>
                  {list.map((item) => {
                    const {poiId, poiName, categoryName, imageInfo, special} = item;
                    const rankNum = rank++;

                    return (
                      <li key={poiId} className={s.cell}>
                        <button
                          type="button"
                          className={s.item}
                          onClick={() => handleClick(item, rankNum)}
                        >
                          <div className={s.ranking}>
                            <em className={s.no}>{rankNum}</em>
                            {special.rankingUpDownInfo?.status && (
                              <span className={s.ico}>
                                {RANKING_UP_DOWN_ICON[special.rankingUpDownInfo.status]}
                              </span>
                            )}
                          </div>
                          <Thumbnail
                            imgSrc={
                              !!imageInfo?.length
                                ? imageInfo[0].url || imageInfo[0].url2
                                : undefined
                            }
                            width={48}
                            height={48}
                            alt={`${poiName} 이미지`}
                          />
                          <div className={s.info}>
                            <em className={s.name}>{poiName}</em>
                            <SearchPoiItemInfo
                              starPoint={special?.avgStar}
                              reviewCount={special?.reviewCount}
                              category={categoryName}
                            />
                          </div>
                        </button>
                      </li>
                    );
                  })}
                </ul>
                {rankList.length > 3 && ranks.length === index + 1 && (
                  <button type="button" className={s.more} onClick={handleClickMore}>
                    <IcArrowRight width={24} height={24} color={'gray600'} />
                  </button>
                )}
              </div>
            );
          })}
        </Flicking>
      </div>
    </SearchMainSection>
  );
};

export default SearchRecommendRanking;
