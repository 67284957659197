import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import useSearchAddress from 'hooks/useSearchAddress';
import {ADDRESS_MODE_STEP, TAddressMap} from 'constant/Address';
import {EAddressMode, TAddressItem} from 'types/Search';
import {IcoArrowRightBold} from 'components/@tmds/icons/IcoArrowRightBold';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import {IcMenu1Fill} from 'components/@tmds/icons/v1.2/IcMenu1Fill';

import s from 'styles/components/search/SearchRankingDropDown.module.scss';

export const NATIONAL_REGION = {
  areaName: '전국',
  areaId: '0',
};

export const ONE_DEPTH_REGION = [NATIONAL_REGION.areaName, '세종'];
export const THREE_DEPTH_REGION = ['서울', '경기'];
export const WHOLE_REGION = '전체';

type TProps = {
  filterAddressMap: TAddressMap;
  setFilterAddressMap: React.Dispatch<
    React.SetStateAction<Partial<Record<EAddressMode, TAddressItem>>>
  >;
  ignoreRef: React.RefObject<HTMLDivElement>;
  getList: (pagingParam?: any) => any;
};

const SearchRankingDropDown = ({
  filterAddressMap,
  setFilterAddressMap,
  ignoreRef,
  getList,
}: TProps) => {
  const dispatch = useAppDispatch();
  const {rdLayout} = useAppSelector((state) => ({rdLayout: state.layout}));
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const refDropDown = useRef<HTMLDivElement>(null);
  const refPrevResult = useRef<TAddressItem[]>([]);
  const refTopElement = useRef<HTMLDivElement>(null);

  const addressItemCount =
    rdLayout.windowSize.width >= 640 ? 6 : rdLayout.windowSize.width >= 360 ? 3 : 2;

  const {nowMode, regionId, depth} = useMemo(() => {
    const mode = ADDRESS_MODE_STEP.find((i) => !filterAddressMap[i]) || EAddressMode.CATE2;
    const cityName = filterAddressMap[EAddressMode.CATE1]?.areaName || '';

    // 전국, 세종은 1depth만 선택 가능
    if (mode === EAddressMode.CATE2 && ONE_DEPTH_REGION.includes(cityName)) {
      return {
        depth: 0,
      };
    }

    // 서울, 경기는 3depth까지 선택 가능, 나머지는 2depth까지만 선택 가능
    if (mode === EAddressMode.CATE3 && !THREE_DEPTH_REGION.includes(cityName)) {
      return {
        depth: 0,
      };
    }

    return {
      depth: mode !== EAddressMode.DETAIL ? parseInt(mode, 10) : 0,
      nowMode: mode,
      regionId:
        filterAddressMap[EAddressMode.CATE2]?.areaId ||
        filterAddressMap[EAddressMode.CATE1]?.areaId,
    };
  }, [filterAddressMap]);

  const result = useSearchAddress({depth, regionId});
  const rankingList = !!result?.data?.length ? result.data : refPrevResult.current;

  const handleClickItem = useCallback((address, mode) => {
    setFilterAddressMap((prev) => {
      const newMap = {...prev, [mode]: address};

      return newMap;
    });
  }, []);

  useEffect(() => {
    const getAreaCodes = (category: TAddressItem) => {
      const {areaDepth1Code, areaDepth2Code, areaDepth3Code} = category;

      return {areaCode1: areaDepth1Code, areaCode2: areaDepth2Code, areaCode3: areaDepth3Code};
    };

    const updateAddressMap = () => {
      dispatch(actions.tRank.updateCurrentAddressMap(filterAddressMap));
    };

    if (!filterAddressMap) {
      return;
    }

    const CATE1 = filterAddressMap[EAddressMode.CATE1];
    const CATE2 = filterAddressMap[EAddressMode.CATE2];
    const CATE3 = filterAddressMap[EAddressMode.CATE3];
    const cate1AreaName = CATE1?.areaName || '';
    const cate2AreaName = CATE2?.areaName || '';
    const cate3AreaName = CATE3?.areaName || '';

    if (CATE1 && (ONE_DEPTH_REGION.includes(cate1AreaName) || cate2AreaName === WHOLE_REGION)) {
      getList(getAreaCodes(CATE1));
      updateAddressMap();
      setFilterAddressMap({});
      return;
    }

    if (CATE2 && (!THREE_DEPTH_REGION.includes(cate1AreaName) || cate3AreaName === WHOLE_REGION)) {
      getList(getAreaCodes(CATE2));
      updateAddressMap();
      setFilterAddressMap({});
      return;
    }

    if (CATE3 && THREE_DEPTH_REGION.includes(cate1AreaName)) {
      getList(getAreaCodes(CATE3));
      updateAddressMap();
      setFilterAddressMap({});
      return;
    }
  }, [filterAddressMap]);

  useEffect(() => {
    if (result.data?.length) {
      setShowDropDown(true);
      refPrevResult.current = result.data;
    }
  }, [result.data, depth]);

  useEffect(() => {
    const refRankingFilter = ignoreRef.current;

    const handleClickOutside = (e: MouseEvent) => {
      const popupElement = refDropDown.current;
      const clickedTarget = e.target as Node;

      if (refRankingFilter && refRankingFilter.contains(clickedTarget)) {
        return;
      }

      if (popupElement && !popupElement.contains(clickedTarget)) {
        setShowDropDown(false);
        setFilterAddressMap({});
        refPrevResult.current = [];
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (refTopElement.current) {
      refTopElement.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [result]);

  if (!showDropDown) {
    return null;
  }

  return (
    <div className={s.wrap}>
      <div className={s.popup_wrap} ref={refDropDown}>
        <div
          className={classNames(s.title, {
            [s.invisible]: !THREE_DEPTH_REGION.includes(
              filterAddressMap[EAddressMode.CATE1]?.areaName || ''
            ),
          })}
        >
          <div className={s.city}>
            {(filterAddressMap[EAddressMode.CATE2]?.areaName && (
              <span className={s.name}>{filterAddressMap[EAddressMode.CATE2].areaName}</span>
            )) ||
              '시/군/구'}
          </div>
          <div className={s.icon}>
            <IcoArrowRightBold width={16} height={16} color="iconTertiary" />
          </div>
          <div className={s.local}>읍/면/동</div>
        </div>
        <div className={s.list}>
          <div
            className={classNames(s.item, s.whole_area)}
            onClick={() =>
              handleClickItem({areaId: NATIONAL_REGION.areaId, areaName: WHOLE_REGION}, nowMode)
            }
            ref={refTopElement}
          >
            <IcMenu1Fill color="iconSecondary" width={20} height={20} />
            전체
          </div>
          {rankingList.map((item, i) => {
            const index = i + 1;
            // 전국 count 에 추가
            const page = Math.ceil((index + 1) / addressItemCount);
            const lastPage = Math.ceil((rankingList.length + 1) / addressItemCount);

            return (
              <div
                key={item.areaId}
                className={classNames(s.item, {
                  [s.hide_border]: page === lastPage,
                })}
                onClick={() => handleClickItem(item, nowMode)}
              >
                {item.areaName}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SearchRankingDropDown;
