import {useMemo} from 'react';
import {useAppSelector} from 'ducks/hooks';
import DiscoverySection from './DiscoverySection';
import DiscoveryRowPoiItem from './DiscoveryRowPoiItem';

import s from '../../../styles/components/tplacehome/TPlaceDiscovery.module.scss';

const DiscoveryWeekend = () => {
  const {tplacehome} = useAppSelector((state) => state);

  const poiList = useMemo(() => tplacehome.discoveryData.result.data.weekendList, [tplacehome]);

  return (
    <DiscoverySection mainTitle={'#주말에 가볼만한 곳'} subTitle={'내 취향을 담은'} pb={14}>
      <ul className={s.row_list}>
        {poiList.map((item, index) => {
          return (
            <li key={index} className={s.cell}>
              <DiscoveryRowPoiItem data={item} />
            </li>
          );
        })}
      </ul>
    </DiscoverySection>
  );
};

export default DiscoveryWeekend;
