import {useCallback, useEffect, useState} from 'react';
import {ERPFlag, TPoiImageInfo} from 'types/App';
import {BUSINESS_HOUR_LABEL} from 'utils/special';
import {useParsePoiData} from 'hooks/useParsePoiData';
import useMoveToTarget from 'hooks/useMoveToTarget';
import {TRankItem} from 'ducks/rank/types';
import useThrottle from 'hooks/useThrottle';
import {parsePoiInfoToNavInfo} from 'utils/search';
import useAddress from 'hooks/useAddress';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import useAppScheme from 'hooks/useAppScheme';
import {fetchUserData} from 'ducks/userInfo/slice';
import useFavorite from 'hooks/useFavorite';
import RankingPoiInfo from './RankingPoiInfo';
import RankingPoiTitle from './RankingPoiTitle';
import RankingPoiSubInfo from './RankingPoiSubInfo';
import RankingPoiImageList from './RankingPoiImageList';

import s from 'styles/components/ranking/RankingPoiCardViewItem.module.scss';

type TProps = {
  idx: number;
  focus?: boolean;
  poiData: {
    imageInfo?: TPoiImageInfo[];
    listName: string;
  } & TRankItem;
  onClickItem?: (e) => void;
};

const RankingPoiCardViewItem = ({poiData, onClickItem}: TProps) => {
  const {
    currentAddressMap,
    width: windowWidth,
    personalPlace,
  } = useAppSelector((state) => ({
    currentAddressMap: state.tRank.currentAddressMap,
    width: state.layout.windowSize.width,
    personalPlace: state.userInfo.personalPlace,
  }));
  const {poiName, categoryName, special, comment, addressNameDepth2} = poiData;
  const {distance, imageInfo: images, headingScore, tags} = useParsePoiData(poiData);
  const {moveToDetail} = useMoveToTarget({});
  const ableToRun = useThrottle();
  const {checkIsFavorite} = useFavorite();
  const {fullAddress} = useAddress(poiData);
  const dispatch = useAppDispatch();
  const inApp = useAppScheme();

  const {unit} = special?.catchTableWaitingResponse || {};
  const businessHoursInfo =
    special?.businessHourStatus && BUSINESS_HOUR_LABEL[special.businessHourStatus];

  const [isFavorite, setIsFavorite] = useState<boolean>(checkIsFavorite(poiData));

  const handleClickFavorite = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (!poiData || !ableToRun()) {
        return;
      }

      inApp
        .toggleFavorite({
          ...parsePoiInfoToNavInfo({
            ...poiData,
            address: fullAddress,
            tel: '',
            navX: `${poiData.navX}`,
            navY: `${poiData.navY}`,
            centerX: `${poiData.centerX}`,
            centerY: `${poiData.centerY}`,
            rpFlag: ERPFlag.N_0000,
            navSeq: '',
          }),
        })
        .then((isOn) => {
          dispatch(fetchUserData());
          setIsFavorite(isOn ? true : false);
        });
    },
    [ableToRun, dispatch, fullAddress, inApp, poiData]
  );

  const handleClickItem = useCallback(
    (e) => {
      moveToDetail(poiData);
      onClickItem?.(e);
    },
    [moveToDetail, poiData, onClickItem]
  );

  useEffect(() => {
    if (!poiData || !personalPlace.loaded) {
      return;
    }

    const isFavoriteCheck = checkIsFavorite(poiData);

    if (isFavorite !== isFavoriteCheck) {
      setIsFavorite(isFavoriteCheck);
    }
  }, [personalPlace.loaded]);

  return (
    <div className={s.wrap} onClick={handleClickItem}>
      <RankingPoiImageList images={images} poiName={poiName} isCardMode={true} />

      <div className={s.contents_wrap}>
        <RankingPoiTitle
          poiName={poiName}
          categoryName={categoryName}
          isFavorite={isFavorite}
          handleClickFavorite={handleClickFavorite}
        />

        <RankingPoiInfo
          className={s.info_wrap}
          businessHoursInfo={businessHoursInfo}
          special={special}
          headingScore={headingScore}
          unit={unit}
        />

        {comment && (
          <div className={s.comment_wrap}>
            <div className={s.intro}>{comment}</div>
          </div>
        )}

        <div className={s.sub_info_wrap}>
          <RankingPoiSubInfo
            distance={distance}
            addressNameDepth2={addressNameDepth2}
            currentAddressMap={currentAddressMap}
            windowWidth={windowWidth}
            tags={tags}
          />
        </div>
      </div>
    </div>
  );
};

export default RankingPoiCardViewItem;
