import {IcViewList} from 'components/@tmds/icons/v1.2/IcViewList';

import s from 'styles/components/ranking/RankingListModeButton.module.scss';

const RankingListModeButton = ({onclick}) => {
  return (
    <div className={s.wrap} onClick={onclick}>
      <button className={s.mode_button}>
        <IcViewList width={24} height={24} />
      </button>
    </div>
  );
};

export default RankingListModeButton;
