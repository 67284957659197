import {useMemo} from 'react';
import {useAppSelector} from 'ducks/hooks';
import DiscoverySection from './DiscoverySection';
import DiscoveryColumnPoiItem from './DiscoveryColumnPoiItem';

import s from '../../../styles/components/tplacehome/TPlaceDiscovery.module.scss';

const DiscoverySimilar = () => {
  const {tplacehome} = useAppSelector((state) => state);

  const poiList = useMemo(() => {
    const list = tplacehome.discoveryData.result.data.similarList.slice(0, 4);

    if (list.length <= 1) {
      return null;
    }
    return list.length % 2 === 0 ? list : list.slice(0, 2);
  }, [tplacehome]);

  if (!poiList) {
    return null;
  }

  return (
    <DiscoverySection mainTitle={'#내 취향 맛집'} subTitle={'내가 자주간 곳과 비슷한'}>
      <ul className={s.grid_list}>
        {poiList.map((item, index) => {
          return (
            <li key={index} className={s.cell}>
              <DiscoveryColumnPoiItem data={item} thumbHeight={110} />
            </li>
          );
        })}
      </ul>
    </DiscoverySection>
  );
};

export default DiscoverySimilar;
