import {useMemo} from 'react';

import {useAppSelector} from 'ducks/hooks';
import useRecentQuery from './useRecentQuery';
import useAddress from './useAddress';
import {getRecentItemType} from 'utils/tmapUtils';

import {ERecentItemType, TRecentItem} from 'types/Search';
import {SEARCH_RECENT_MAX_COUNT} from 'constant/Search';

type TProps = {
  listLength: number;
};

export const useRecentList = ({listLength}: TProps = {listLength: SEARCH_RECENT_MAX_COUNT}) => {
  const {
    recentDestinationApiLoaded,
    recentDestination,
    publicRecentDestination,
    home,
    office,
    favorites,
  } = useAppSelector((state) => ({
    recentDestinationApiLoaded: state.userInfo.personalPlace.loaded,
    recentDestination: state.userInfo.personalPlace.data.recentDestination,
    publicRecentDestination: state.userInfo.personalPlace.data.publicRecentDestination,
    home: state.userInfo.personalPlace.data.home,
    office: state.userInfo.personalPlace.data.office,
    favorites: state.userInfo.personalPlace.data.favorites,
  }));
  const {recentQueries} = useRecentQuery();
  const {isUseJibun} = useAddress();

  const recentList: TRecentItem[] = useMemo(() => {
    return [
      ...(recentDestinationApiLoaded ? recentDestination : []).map(
        (n, i): TRecentItem => ({
          id: `${n.poiId}-${i}`,
          type: getRecentItemType({item: n, home, office, favorites}),
          name: n.customName,
          date: n.recentDateTime || '',
          poiInfo: {
            ...n,
            addInfo: isUseJibun ? n.fullJibunAddr : n.fullRoadAddr || n.fullJibunAddr,
          },
        })
      ),
      ...(recentDestinationApiLoaded ? publicRecentDestination : []).map((n, i) => ({
        ...n,
        id: `${n.transportId}-${i}`,
        poiInfo: n,
      })),
      ...recentQueries
        .map(
          (n): TRecentItem => ({
            id: n.query,
            type: ERecentItemType.QUERY,
            name: n.query,
            date: n.date,
          })
        )
        .filter((i) => !!i.id)
        .sort((a, b) => (a.date < b.date ? 1 : -1))
        .slice(0, listLength),
    ]
      .filter((i) => !!i.id)
      .sort((a, b) => (a.date < b.date ? 1 : -1));
  }, [
    recentDestinationApiLoaded,
    recentDestination,
    recentQueries,
    listLength,
    isUseJibun,
    home,
    office,
    favorites,
    publicRecentDestination,
  ]);

  return {
    recentList,
    recentListApiLoaded: recentDestinationApiLoaded,
  };
};

export default useRecentList;
