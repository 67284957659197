import ImageLoader from 'components/ImageLoader';
import {TTPlaceCurationType3} from 'ducks/remote/type';
import {moveByLandingType} from 'utils/url';

import s from 'styles/components/tplacehome/TPlaceCurationType3.module.scss';

type TProps = {
  item: TTPlaceCurationType3;
};

const TPlaceCurationType3 = ({item}: TProps) => {
  return (
    <div className={s.wrap}>
      <h4 dangerouslySetInnerHTML={{__html: item.title}} />
      {item.list.length > 0 && (
        <ul className={s.list} data-flicking-ignore="true">
          {item.list.map((v, i) => (
            <li
              key={i}
              className={s.item}
              onClick={() => {
                moveByLandingType(v.landingType, v.landingUrl);
              }}
            >
              <ImageLoader src={v.thumbnailUrl} alt={'이미지'} className={s.image} />
              <div className={s.gradient} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TPlaceCurationType3;
