import {WHOLE_REGION} from 'components/search/SearchRankingDropDown';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {fetchTRankList} from 'ducks/rank/slice';
import {useCallback} from 'react';
import {EAddressMode} from 'types/Search';
import actions from 'ducks/actions';

import s from 'styles/components/ranking/RankingExpandPopup.module.scss';

const RankingExpandPopup = () => {
  const {currentAddressMap} = useAppSelector((state) => state.tRank);
  const dispatch = useAppDispatch();

  const updateAddressMap = useCallback(
    (addressMap) => {
      dispatch(actions.tRank.updateCurrentAddressMap(addressMap));
    },
    [dispatch]
  );

  const handleClick = useCallback(async () => {
    const areaCode1 = currentAddressMap[EAddressMode.CATE1]?.areaDepth1Code;
    const areaCode2 = currentAddressMap[EAddressMode.CATE2]?.areaDepth2Code;
    const areaCode3 = currentAddressMap[EAddressMode.CATE3]?.areaDepth3Code;

    if (!areaCode3 || areaCode3 === WHOLE_REGION) {
      // 2depth 까지 선택된 경우
      dispatch(
        fetchTRankList({
          areaCode1,
        })
      );

      updateAddressMap({
        [EAddressMode.CATE1]: currentAddressMap[EAddressMode.CATE1],
      });
      return;
    }

    // 3depth 까지 선택된 경우 (서울 / 경기)
    dispatch(
      fetchTRankList({
        areaCode1,
        areaCode2,
      })
    );

    updateAddressMap({
      [EAddressMode.CATE1]: currentAddressMap[EAddressMode.CATE1],
      [EAddressMode.CATE2]: currentAddressMap[EAddressMode.CATE2],
    });
  }, [currentAddressMap, dispatch]);

  // DrawerContainer에서 listBottomPadding 값을 주기 위해 data-type='popup' 추가
  return (
    <div className={s.wrap} data-type="popup">
      <div className={s.text_wrap}>
        <span className={s.highlight}>반경을 넓히면</span>
        <span>더 많은 장소를 볼 수 있어요!</span>
      </div>

      <div className={s.button_wrap}>
        <div className={s.button} onClick={handleClick}>
          지역 넓히기
        </div>
      </div>
    </div>
  );
};

export default RankingExpandPopup;
