import {useCallback, useMemo} from 'react';
import Vsm, {
  ScreenPointCompatible,
  ScreenBoundsCompatible,
  QueryRenderedFeaturesOptions,
  QueryResultRenderedFeatureCollection,
  GeoJsonFeature,
  PaddingOptions,
  Point,
  EventData,
} from '@vsm/vsm';

import {TBounds, TLonLat} from 'types/Map';

import {DEFAULT_ZOOM} from 'constant/Map';

import {useVSMInterfaceConsumer} from 'context/VSMInterfaceContext';

import {useAppSelector} from 'ducks/hooks';
import {TMapViewPort} from 'ducks/map/types';

import {devLog} from 'utils/dev';
import {convertToLonLat} from 'utils/map';
import {getBoundary} from 'utils/general';

type TCenterProps = TLonLat & {
  zoom?: number;
};

type TOffset = {
  x?: number;
  y?: number;
};

type TOption = {
  speed?: number;
  animate?: boolean;
  duration?: number;
  offset?: Required<TOffset>;
  eventData?: EventData;
};

const DEFAULT_FEATURES_BUFFER = 3;

const useMap = () => {
  const {camera, map} = useVSMInterfaceConsumer();
  const {style} = useAppSelector((state) => ({style: state.map.style}));

  const isInitialized = useMemo(() => !!map, [map]);

  const getCenter = useCallback(() => {
    if (camera) {
      return camera.getCenter();
    }
  }, [camera]);

  const getBearing = useCallback(() => camera?.getBearing(), [camera]);
  const setBearing = useCallback((bearing) => camera?.setBearing(bearing), [camera]);
  const getPitch = useCallback(() => camera?.getPitch(), [camera]);
  const setPitch = useCallback((pitch) => camera?.setPitch(pitch), [camera]);

  const getBounds = useCallback(() => {
    if (camera) {
      const {northEast, southWest} = camera.getBounds();

      return {
        west: southWest.lng,
        south: southWest.lat,
        east: northEast.lng,
        north: northEast.lat,
      };
    }
  }, [camera]);

  const panBy = useCallback(
    ({x = 0, y = 0}: TOffset) => {
      camera?.panBy({
        x,
        y,
      });
    },
    [camera]
  );

  const moveToCenterWithOffset = useCallback(
    ({lon, lat}: TLonLat, {x = 0, y = 0}: TOffset) => {
      const transform = map?.getTransform();
      const center = {lat, lng: lon};

      if (transform) {
        const point = transform.lngLatToScreenPoint(center);
        const mapHeight = transform.getHeight();
        const mapWidth = transform.getWidth();

        const centerX = mapWidth / 2 - point.x;
        const centerY = mapHeight / 2 - point.y;

        panBy({
          x: -(centerX + x),
          y: -(centerY + y),
        });
      }
    },
    [map, panBy]
  );

  const getMinMaxBounds = useCallback(() => {
    const bounds = getBounds();

    if (!bounds) {
      return;
    }

    const {east, west, south, north} = bounds;

    return {
      maxLat: north,
      minLat: south,
      maxLon: east,
      minLon: west,
    };
  }, [getBounds]);

  const getFixedZoomLevel = useCallback(() => {
    if (!camera) {
      return DEFAULT_ZOOM;
    }

    return Math.floor(camera.getZoom());
  }, [camera]);

  const moveToCenter = useCallback(
    (
      {lat, lon, zoom}: TCenterProps,
      option: TOption = {speed: 0.5, animate: true, offset: undefined, eventData: undefined}
    ) => {
      if (!lat || !lon) {
        return;
      }

      camera?.flyTo(
        {
          center: {lat, lng: lon},
          ...(zoom && {zoom}),
        },
        {
          ...(option.duration ? {duration: option.duration} : {}),
          speed: option.speed,
          // animate = false 는 camera.jumpTo와 동일
          animate: option.animate,
          offset: option.offset
            ? {
                x: option.offset.x,
                y: option.offset.y,
              }
            : undefined,
        },
        option.eventData
      );
    },
    [camera]
  );

  const moveCoordIntoView = useCallback(
    (
      lonLat?: TLonLat,
      padding: PaddingOptions = {top: 0, left: 0, right: 0, bottom: 0}
    ): Nullable<TBounds> => {
      const {lon: lng, lat} = lonLat || {};

      if (!map || !lng || !lat || !padding) {
        return null;
      }

      const transform = map.getTransform();

      const {x, y} = transform.lngLatToScreenPoint({lng, lat});
      const mapWidth = transform.getWidth();
      const mapHeight = transform.getHeight();
      const boxPoint = {
        top: padding.top,
        left: padding.left,
        right: mapWidth - padding.right,
        bottom: mapHeight - padding.bottom,
      };

      if (boxPoint.left < x && x < boxPoint.right && boxPoint.top < y && y < boxPoint.bottom) {
        return null;
      }

      const newX = getBoundary(x, {min: boxPoint.left, max: boxPoint.right});
      const newY = getBoundary(y, {min: boxPoint.top, max: boxPoint.bottom});

      panBy({
        x: x - newX,
        y: y - newY,
      });

      return null;
    },
    [map, panBy]
  );

  const getCoordScreenPoint = useCallback(
    (lonLat: TLonLat) => {
      if (!map) {
        return null;
      }
      const transform = map.getTransform();

      if (!transform) {
        return null;
      }

      return transform.lngLatToScreenPoint({lng: lonLat.lon, lat: lonLat.lat});
    },
    [map]
  );

  const removeLayer = useCallback(
    (layerId) => {
      map?.removeLayer(Number(layerId)).catch((e) => {
        devLog(e);
      });
    },
    [map]
  );

  const getFeatureCollection = useCallback(
    (
      screenPoint: ScreenPointCompatible | ScreenBoundsCompatible,
      option?: QueryRenderedFeaturesOptions
    ): QueryResultRenderedFeatureCollection | undefined => {
      return map?.queryRenderedFeatures(screenPoint, {
        buffer: DEFAULT_FEATURES_BUFFER,
        ...option,
      });
    },
    [map]
  );

  const getTmapFeature = useCallback(
    (screenPoint: ScreenPointCompatible | ScreenBoundsCompatible): GeoJsonFeature | undefined => {
      if (!map) {
        return;
      }

      const collection = getFeatureCollection(screenPoint, {
        buffer: 0,
        lookupFilter: Vsm.Extensions.TmapUtils.getClickableLookupFilter(map),
      });

      if (!collection) {
        return;
      }

      const feature = collection.features.filter((c) => c.geometry.type === 'Point');

      return feature.length > 0 ? feature[0] : undefined;
    },
    [map, getFeatureCollection]
  );

  const getMapViewPort = (offset?: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  }): TMapViewPort | undefined => {
    if (!map) {
      return;
    }

    const t = map.getTransform();
    const w = t.getWidth();
    const h = t.getHeight();

    const left = 0 + (offset?.left || 0);
    const top = 0 + (offset?.top || 0);
    const right = w - (offset?.right || 0);
    const bottom = h - (offset?.bottom || 0);

    return {
      leftTop: convertToLonLat(t.screenPointToLngLat({x: left, y: top})),
      rightTop: convertToLonLat(t.screenPointToLngLat({x: right, y: top})),
      rightBottom: convertToLonLat(t.screenPointToLngLat({x: right, y: bottom})),
      leftBottom: convertToLonLat(t.screenPointToLngLat({x: left, y: bottom})),
    };
  };

  const getOffsetCenter = useCallback(
    (offset: Point) => {
      if (camera && map) {
        const center = camera.getCenter();
        const transform = map.getTransform();
        const {x, y} = transform.lngLatToScreenPoint(center);

        return transform.screenPointToLngLat({x: x + offset.x, y: y + offset.y});
      }
    },
    [camera, map]
  );

  const resize = useCallback(() => {
    if (map) {
      map.resize();
    }
  }, [map]);

  return {
    isInitialized,
    style,
    moveToCenter,
    panBy,
    resize,
    removeLayer,
    getCenter,
    getOffsetCenter,
    getBounds,
    getMinMaxBounds,
    getFixedZoomLevel,
    moveToCenterWithOffset,
    getFeatureCollection,
    getTmapFeature,
    getBearing,
    getPitch,
    setBearing,
    setPitch,
    getMapViewPort,
    moveCoordIntoView,
    getCoordScreenPoint,
  };
};

export default useMap;
