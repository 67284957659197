import {useCallback, useEffect, useState} from 'react';
import {ERankingUpDownStatus, ERPFlag, TPoiImageInfo} from 'types/App';
import {useParsePoiData} from 'hooks/useParsePoiData';
import {ReactComponent as IcoNew} from 'resource/images/ico_new.svg';
import {ReactComponent as IcoRankingUp} from 'resource/images/ico_ranking_up.svg';
import {ReactComponent as IcoRankingDown} from 'resource/images/ico_ranking_down.svg';
import ImageLoader from 'components/ImageLoader';
import {IcoDotBetweenBold} from 'components/@tmds/icons/IcoDotBetweenBold';
import {TRankItem} from 'ducks/rank/types';
import useFavorite from 'hooks/useFavorite';
import useThrottle from 'hooks/useThrottle';
import useAppScheme from 'hooks/useAppScheme';
import useAddress from 'hooks/useAddress';
import {parsePoiInfoToNavInfo} from 'utils/search';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {fetchUserData} from 'ducks/userInfo/slice';
import RankingPoiInfo from './RankingPoiInfo';
import {RankingPoiDistance} from './RankingPoiSubInfo';
import {RankingFavorite} from './RankingPoiTitle';

import s from 'styles/components/ranking/RankingPoiItem.module.scss';

type TProps = {
  idx: number;
  poiData: {
    imageInfo?: TPoiImageInfo[];
  } & TRankItem;
  onClickItem: (e) => void;
};

export const RANKING_UP_DOWN_ICON = {
  [ERankingUpDownStatus.UP]: <IcoRankingUp />,
  [ERankingUpDownStatus.DOWN]: <IcoRankingDown />,
  [ERankingUpDownStatus.NEW]: <IcoNew />,
};

const RankingPoiItem = ({idx, poiData, onClickItem}: TProps) => {
  const {currentAddressMap, activePoi, personalPlace} = useAppSelector((state) => ({
    currentAddressMap: state.tRank.currentAddressMap,
    activePoi: state.userInteraction.activePoi,
    personalPlace: state.userInfo.personalPlace,
  }));
  const {poiName, categoryName, special, addressNameDepth2} = poiData;
  const {distance, imageInfo: images, headingScore} = useParsePoiData(poiData);
  const {checkIsFavorite} = useFavorite();
  const ableToRun = useThrottle();
  const inApp = useAppScheme();
  const dispatch = useAppDispatch();
  const {fullAddress} = useAddress(poiData);

  const {unit} = special?.catchTableWaitingResponse || {};
  const [isFavorite, setIsFavorite] = useState<boolean>(checkIsFavorite(poiData));

  const handleClickFavorite = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (!poiData || !ableToRun()) {
        return;
      }

      inApp
        .toggleFavorite({
          ...parsePoiInfoToNavInfo({
            ...poiData,
            address: fullAddress,
            tel: '',
            navX: `${poiData.navX}`,
            navY: `${poiData.navY}`,
            centerX: `${poiData.centerX}`,
            centerY: `${poiData.centerY}`,
            rpFlag: ERPFlag.N_0000,
            navSeq: '',
          }),
        })
        .then((isOn) => {
          dispatch(fetchUserData());
          setIsFavorite(isOn ? true : false);
        });
    },
    [ableToRun, dispatch, fullAddress, inApp, poiData]
  );

  useEffect(() => {
    if (!poiData || !personalPlace.loaded) {
      return;
    }

    const isFavoriteCheck = checkIsFavorite(poiData);

    if (isFavorite !== isFavoriteCheck) {
      setIsFavorite(isFavoriteCheck);
    }
  }, [personalPlace.loaded]);

  return (
    <div
      className={s.wrap}
      onClick={onClickItem}
      data-type="poi"
      data-id={poiData.listId}
      data-focus={activePoi === poiData.listId}
    >
      <div className={s.ranking}>
        <span>{poiData.rank}</span>
        {special?.rankingUpDownInfo?.status && (
          <span>{RANKING_UP_DOWN_ICON[special?.rankingUpDownInfo.status]}</span>
        )}
      </div>

      <div className={s.contents_wrap}>
        <div className={s.image_wrap}>
          <ImageLoader
            toggleClass={s.fade_in}
            src={images[0]?.url}
            alt={`${poiData.poiName} 이미지`}
          />
        </div>

        <div className={s.info_wrap}>
          <div className={s.title}>
            <span>{poiName}</span>
          </div>
          <RankingPoiInfo
            businessHoursInfo={null}
            special={special}
            headingScore={headingScore}
            unit={unit}
          />

          <div className={s.sub_info_wrap}>
            <RankingPoiDistance
              distance={distance}
              addressNameDepth2={addressNameDepth2}
              currentAddressMap={currentAddressMap}
            />

            <IcoDotBetweenBold width={12} height={12} color="gray200" />

            <span className={s.category}>{categoryName}</span>
          </div>
        </div>
      </div>

      <RankingFavorite
        isFavorite={isFavorite}
        handleClickFavorite={handleClickFavorite}
        className={s.favorite}
      />
    </div>
  );
};

export default RankingPoiItem;
