import {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppSelector} from 'ducks/hooks';
import DiscoverySection from './DiscoverySection';
import {TDiscoveryItem} from '../../../ducks/tplacehome/types';
import classNames from 'classnames';
import DiscoveryRowPoiItem from './DiscoveryRowPoiItem';

import s from '../../../styles/components/tplacehome/TPlaceDiscovery.module.scss';

enum ETabId {
  RESTAURANT,
  CAFE,
}

type TTab = {
  id: ETabId;
  label: string;
};

const TABS: TTab[] = [
  {
    id: ETabId.RESTAURANT,
    label: '음식점',
  },
  {
    id: ETabId.CAFE,
    label: '카페',
  },
];

const DiscoveryDelicious = () => {
  const {tplacehome} = useAppSelector((state) => state);

  const [data, setData] = useState<TDiscoveryItem[]>([]);
  const [currentTabId, setCurrentTabId] = useState(ETabId.RESTAURANT);

  const poiList = useMemo(() => tplacehome.discoveryData.result.data.deliciousData, [tplacehome]);

  const handleClickTab = useCallback((id: ETabId) => {
    setCurrentTabId(id);
  }, []);

  useEffect(() => {
    setData(currentTabId === ETabId.RESTAURANT ? poiList.restaurants : poiList.cafes);
  }, [currentTabId, poiList]);

  return (
    <DiscoverySection
      mainTitle={'음식점, 카페'}
      subTitle={'내 취향과 비슷한 사람들에게 인기 있는'}
      pb={14}
    >
      <ul className={s.tab}>
        {TABS.map((tab) => {
          return (
            <li key={tab.id}>
              <button
                type="button"
                className={classNames(s.item, {[s.active]: tab.id === currentTabId})}
                onClick={() => handleClickTab(tab.id)}
              >
                {tab.label}
              </button>
            </li>
          );
        })}
      </ul>
      <ul className={s.row_list}>
        {data.map((item, index) => {
          return (
            <li key={`${item.poiId}_${index}`} className={s.cell}>
              <DiscoveryRowPoiItem data={item} />
            </li>
          );
        })}
      </ul>
    </DiscoverySection>
  );
};

export default DiscoveryDelicious;
