import Flicking from '@egjs/react-flicking';
import actions from 'ducks/actions';

import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {useCallback, useEffect, useRef} from 'react';

import Discovery from './discovery/Discovery';
import {ETPlaceTab} from 'ducks/tplacehome/types';
import AroundMe from './aroundMe/AroundMe';

import s from 'styles/components/tplacehome/TPlaceMain.module.scss';
import useWindowSize from 'hooks/useWindowSize';

const TPlaceMain = () => {
  const {tplacehome} = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const refFlicking = useRef<Flicking>(null);
  const wrapRef = useRef<HTMLDivElement>(null);

  const handleFilickingChange = useCallback(
    (index: number) => {
      switch (index) {
        case 0:
          dispatch(actions.tplacehome.setCurrentTab(ETPlaceTab.AROUND_ME));
          break;
        case 1:
          dispatch(actions.tplacehome.setCurrentTab(ETPlaceTab.DISCOVERY));
          break;
      }
    },
    [dispatch]
  );
  const handleFlickingMoveStart = useCallback(() => {
    if (!tplacehome.isFlickingMoved) {
      dispatch(actions.tplacehome.setIsFlikingMoved());
    }
  }, [dispatch, tplacehome.isFlickingMoved]);

  useEffect(() => {
    const index = tplacehome.currentTab === ETPlaceTab.AROUND_ME ? 0 : 1;
    if (refFlicking.current?.animating) {
      refFlicking.current.updateAnimation(index);
    } else {
      refFlicking.current?.moveTo(index);
    }
  }, [tplacehome.currentTab]);

  // 가로 스크롤 엘리먼트 이벤트 차단.
  useEffect(() => {
    wrapRef.current?.addEventListener('touchstart', (e) => {
      const targetEl = e.target as HTMLElement;
      const ignoreEl = targetEl.closest('[data-flicking-ignore]');
      if (ignoreEl) {
        refFlicking.current?.disableInput();
      }
    });
    wrapRef.current?.addEventListener('touchend', (e) => {
      refFlicking.current?.enableInput();
    });
  }, []);

  const windowSize = useWindowSize();
  useEffect(() => {
    refFlicking.current?.resize();
  }, [windowSize.width]);

  return (
    <div className={s.wrap} ref={wrapRef}>
      <Flicking
        ref={refFlicking}
        align="prev"
        onMoveStart={handleFlickingMoveStart}
        onWillChange={(e) => handleFilickingChange(e.index)}
        interruptable={false}
        defaultIndex={tplacehome.currentTab === ETPlaceTab.AROUND_ME ? 0 : 1}
      >
        <div className={s.flicking_item_wrap}>
          <AroundMe />
        </div>
        <div className={s.flicking_item_wrap}>
          <Discovery />
        </div>
      </Flicking>
    </div>
  );
};

export default TPlaceMain;
