import {AdBanner} from 'components/AdBanner';
import {EAdCode, ENaverDaUnitId, ETMapBannerCode, RANKING_BANNER_AD_STEP} from 'constant/Ads';

type TProps = {
  isFirst: boolean;
};

const DEFAULT_BANNER_HEIGHT = 60;

const RankingAdBanner = ({isFirst}: TProps) => {
  return (
    <AdBanner
      bannerHeight={DEFAULT_BANNER_HEIGHT}
      visibleLandscape={false}
      adCode={isFirst ? EAdCode.RANKING_FIRST : EAdCode.RANKING_SECOND}
      adTypeStep={RANKING_BANNER_AD_STEP}
      adTypeOption={{
        inner: {
          inventoryCode: isFirst ? ETMapBannerCode.RANKING_FIRST : ETMapBannerCode.RANKING_SECOND,
        },
        naver: {
          adUnitId: isFirst ? ENaverDaUnitId.RANKING_FIRST : ENaverDaUnitId.RANKING_SECOND,
        },
      }}
    />
  );
};

export default RankingAdBanner;
