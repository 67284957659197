import {TSpecialColor, TSpecialDecorateItem} from 'components/PoiListItemSpecialContent';
import {
  EAccommodationProviderType,
  EBusinessHourStatus,
  EEVChargerSpeed,
  EOilType,
  EParkingStatus,
  TAccommodationInfo,
  TCatchTableWaitingResponse,
} from 'types/App';
import {getComparedPrice, getOndaPrefix, getTime, setComma} from 'utils/formatter';
import {EEVChargerSpeedLabel, MIN_PARKING_LOT_COUNT} from 'constant/Place';
import {getSafeDateFormat} from './date';
import {EDateFormat} from 'types/DateTime';
import {EOnlineWaitingDisableReason} from 'components/CatchTableReservationButton';
import {EAddressMode} from 'types/Search';
import {
  NATIONAL_REGION,
  THREE_DEPTH_REGION,
  WHOLE_REGION,
} from 'components/search/SearchRankingDropDown';
import {TAddressMap} from 'constant/Address';

const GAS_LABEL = {
  [EOilType.GASOLINE]: '휘',
  [EOilType.GASOLINE_PREMIUM]: '고급',
  [EOilType.DIESEL]: '경',
  [EOilType.LPG]: 'LPG',
};

export const BUSINESS_HOUR_LABEL: Record<EBusinessHourStatus, [string, TSpecialColor]> = {
  [EBusinessHourStatus.BEFORE]: ['영업 전', 'red700'],
  [EBusinessHourStatus.OPEN]: ['영업 중', 'gray900'],
  [EBusinessHourStatus.BREAK_TIME]: ['브레이크타임', 'gray900'],
  [EBusinessHourStatus.CLOSING_SOON]: ['곧 영업종료', 'gray900'],
  [EBusinessHourStatus.CLOSED]: ['영업 종료', 'red700'],
  [EBusinessHourStatus.TODAY_OFF]: ['오늘 휴무', 'red500'],
};

const PARKING_LOT_LABEL = {
  [EParkingStatus.GOOD]: [EParkingStatus.GOOD, 'green500'],
  [EParkingStatus.BUSY]: [EParkingStatus.BUSY, 'orange500'],
  [EParkingStatus.FULL]: [EParkingStatus.FULL, 'red500'],
};

const WAITING_DISABLE_REASON_LABEL: Record<string, string> = {
  [EOnlineWaitingDisableReason.OFFLINE_ONLY]: '현장웨이팅만 가능',
  [EOnlineWaitingDisableReason.UNDER_AVAILABLE_TEAMS]: '현재 웨이팅 1팀 이하',
  [EOnlineWaitingDisableReason.ONLY_OFFLINE_OPERATION_HOURS]: '현장웨이팅만 가능',
};

export const getGasPrice = (price) => {
  return {
    [EOilType.GASOLINE]: price?.gasolinePrice,
    [EOilType.GASOLINE_PREMIUM]: price?.premiumGasolinePrice,
    [EOilType.DIESEL]: price?.dieselPrice,
    [EOilType.LPG]: price?.lpgPrice,
  };
};

export const getDecorateGasPrice = (gasStationInfo, filter): TSpecialDecorateItem[] => {
  const price = getGasPrice(gasStationInfo);
  const oilList =
    filter && filter !== EOilType.ALL
      ? [filter]
      : [EOilType.GASOLINE, EOilType.GASOLINE_PREMIUM, EOilType.DIESEL, EOilType.LPG];
  const priceInfo: TSpecialDecorateItem[] = oilList
    .filter((type) => !!price[type])
    .map((type) => ({
      text: `${GAS_LABEL[type]} ${setComma(price[type])}`,
      color: 'purple500',
    }));

  return priceInfo.length > 0 ? priceInfo : [{text: '정보없음', color: 'gray400'}];
};

const getAvailableEVCharger = (data) => {
  const slow = {count: data.slowAvailableCount, available: true};

  const fast =
    data.fastChargerCount > 0
      ? {count: data.fastAvailableCount, available: data.slowChargerCount < 1}
      : undefined;

  const superFast =
    data.superFastChargerCount > 0
      ? {
          count: data.superFastAvailableCount,
          available: data.fastChargerCount < 1 && data.slowChargerCount < 1,
        }
      : undefined;

  return {
    [EEVChargerSpeed.SLOW]: data.slowChargerCount > 0 ? slow : undefined,
    [EEVChargerSpeed.FAST]: data.fastChargerCount > 0 ? fast : undefined,
    [EEVChargerSpeed.SUPER_FAST]: data.superFastChargerCount > 0 ? superFast : undefined,
  };
};

export const getDecorateEV = (evInfo) => {
  const availableCharger = getAvailableEVCharger(evInfo);
  let decoItem: TSpecialDecorateItem[] = [];

  const availableText = [EEVChargerSpeed.SUPER_FAST, EEVChargerSpeed.FAST, EEVChargerSpeed.SLOW]
    .map((speed) => {
      const info = availableCharger[speed];
      const count = info ? `${EEVChargerSpeedLabel[speed]} ${info.count}대` : '';

      return `${count}${info?.available ? ' 가능' : ''}`;
    })
    .filter((t) => !!t)
    .join(', ');

  if (availableText) {
    decoItem.push({
      text: availableText,
      color: 'evBlue500',
    });
  }

  if (evInfo?.lastChargingMinuteAgo) {
    decoItem.push({
      text: `${getTime(evInfo.lastChargingMinuteAgo)} 전 사용`,
      color: 'evBlue500',
    });
  }
  return decoItem.length > 0 ? decoItem : undefined;
};

export const getDecoBusinessHour = (info) => {
  const {businessHourStatus, offDay, businessHours} = info;
  const [statusText, statusColor] =
    (businessHourStatus && BUSINESS_HOUR_LABEL[businessHourStatus]) || [];
  let decoItem: TSpecialDecorateItem[] = [];

  if (statusText) {
    decoItem.push({text: statusText, color: statusColor});
  }

  if (businessHours) {
    decoItem.push({
      text: businessHours.replace(/\s/g, ''),
      color: 'gray900',
    });
  }

  if (offDay) {
    decoItem.push({
      text: `휴무 ${getSafeDateFormat(offDay, EDateFormat.MMddeee)}`,
      color: 'gray900',
    });
  }

  return decoItem.length > 0 ? decoItem : undefined;
};

const getRealTimeStatus = (parkingLot) => {
  const hasRealtime = parkingLot.parkingNumber > MIN_PARKING_LOT_COUNT;
  const isFull = parkingLot.parkingAbleNumber === 0;
  const status = isFull ? EParkingStatus.FULL : parkingLot.parkingStatus;

  return hasRealtime
    ? {
        status,
        availableNumber: parkingLot.parkingAbleNumber,
      }
    : undefined;
};

export const getDecoRealTimeParkingLot = (parkingLot): TSpecialDecorateItem[] | undefined => {
  if (!parkingLot) {
    return undefined;
  }
  const realtime = getRealTimeStatus(parkingLot);

  if (!realtime) {
    return undefined;
  }
  const [label, color] = PARKING_LOT_LABEL[realtime.status];

  return [
    {
      text: `${label}${realtime.availableNumber > 0 ? ` ${realtime.availableNumber}대 가능` : ''}`,
      color: color,
    },
  ];
};

export const getDecoParkingLotFeeInfo = (
  parkingLotFee,
  tmapParkingFee
): TSpecialDecorateItem[] | undefined => {
  if (!parkingLotFee && (tmapParkingFee?.tickets || []).length < 1) {
    return undefined;
  }

  if (tmapParkingFee?.tickets.length > 0) {
    const text = tmapParkingFee?.tickets
      .slice(0, 2)
      .map((n) => `${n.ticketName} ${setComma(n.ticketFee)}원`)
      .join(', ');

    return [{text, color: 'gray800'}];
  }

  let text = '';

  const baseTime =
    parkingLotFee?.parkFeeBaseTime && parkingLotFee.parkFee
      ? `${parkingLotFee?.parkFeeBaseTime}분 ${setComma(parkingLotFee?.parkFee)}원`
      : '';
  const addTime =
    parkingLotFee?.parkFeeAddTime && parkingLotFee.parkFeeAdd
      ? `${parkingLotFee?.parkFeeAddTime}분 ${setComma(parkingLotFee?.parkFeeAdd)}원`
      : '';

  if (baseTime && addTime) {
    text = `기본 ${baseTime}, 추가 ${addTime}`;
  } else if (baseTime) {
    text = baseTime;
  } else if (addTime) {
    text = addTime;
  }

  return text
    ? [
        {
          text,
          color: 'gray800',
        },
      ]
    : undefined;
};

export const getDecoAccommodationInfo = (data: Nullable<TAccommodationInfo> | undefined) => {
  const decoItem: TSpecialDecorateItem[] = [];
  const {provider, dayUseMinPrice, dayUseMaxPrice, roomMinPrice, roomMaxPrice} = data || {};

  if (!data || !provider) {
    return null;
  }

  const getPrice = {
    [EAccommodationProviderType.GOODCHOICE]: (minPrice, maxPrice, text) => ({
      text: getComparedPrice(minPrice, maxPrice, text),
      color: 'gray800' as TSpecialColor,
    }),
    [EAccommodationProviderType.ONDA]: (minPrice, maxPrice, text) => ({
      text: getComparedPrice(minPrice, maxPrice, getOndaPrefix(data) + text),
      color: data?.isLowestPrice ? 'pink600' : ('gray800' as TSpecialColor),
    }),
  }[provider];

  dayUseMinPrice && decoItem.push(getPrice(dayUseMinPrice, dayUseMaxPrice, '대실'));
  roomMinPrice && decoItem.push(getPrice(roomMinPrice, roomMaxPrice, '숙박'));

  return decoItem.length > 0 ? decoItem : null;
};

export const getWaitingInfo = (data: TCatchTableWaitingResponse) => {
  const {isAvailableOnlineWaiting, onlineWaitingDisableReason, unit} = data;

  const hasWaitingInfo =
    isAvailableOnlineWaiting ||
    [
      EOnlineWaitingDisableReason.OFFLINE_ONLY,
      EOnlineWaitingDisableReason.UNDER_AVAILABLE_TEAMS,
      EOnlineWaitingDisableReason.BY_PASS,
      EOnlineWaitingDisableReason.ONLY_OFFLINE_OPERATION_HOURS,
    ].includes(onlineWaitingDisableReason as EOnlineWaitingDisableReason);

  if (!hasWaitingInfo) {
    return null;
  }

  return [
    {
      text:
        WAITING_DISABLE_REASON_LABEL[onlineWaitingDisableReason] ||
        `현재 웨이팅 ${unit?.count ?? 0}팀`,
      color: 'pink600',
    },
  ];
};

const DEFAULT_REGION_CODE = '000';

export const getAddressMapFromReverseGeo = (data) => {
  const {
    region1FullCode,
    region2FullCode,
    region3FullCode,
    region1Code,
    region2Code,
    region3Code,
    region1Name,
    region2Name,
    region3Name,
  } = data;

  return {
    [EAddressMode.CATE1]: {
      areaId: region1FullCode,
      areaDepth1Code: region1Code,
      areaDepth2Code: DEFAULT_REGION_CODE,
      areaDepth3Code: DEFAULT_REGION_CODE,
      areaName: region1Name,
    },
    [EAddressMode.CATE2]: {
      areaId: region2FullCode,
      areaDepth1Code: region1Code,
      areaDepth2Code: region2Code,
      areaDepth3Code: DEFAULT_REGION_CODE,
      areaName: region2Name,
    },
    [EAddressMode.CATE3]: THREE_DEPTH_REGION.includes(region1Name)
      ? {
          areaId: region3FullCode,
          areaDepth1Code: region1Code,
          areaDepth2Code: region2Code,
          areaDepth3Code: region3Code,
          areaName: region3Name,
        }
      : null,
  };
};

export const getAddressMapFromAreaCode = (data): TAddressMap => {
  const {areaCode1, areaName, areaId} = data;

  return {
    [EAddressMode.CATE1]: {
      areaId,
      areaDepth1Code: areaCode1,
      areaDepth2Code: DEFAULT_REGION_CODE,
      areaDepth3Code: DEFAULT_REGION_CODE,
      areaName,
    },
    [EAddressMode.CATE2]: {
      areaId: NATIONAL_REGION.areaId,
      areaDepth1Code: '',
      areaDepth2Code: '',
      areaDepth3Code: '',
      areaName: WHOLE_REGION,
    },
  };
};
