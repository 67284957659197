import classNames from 'classnames';
import {IcoStar} from 'components/@tmds/icons/IcoStar';
import {IcoStarFilled} from 'components/@tmds/icons/IcoStarFilled';

import s from 'styles/components/ranking/RankingPoiTitle.module.scss';

type TFavoriteProps = {
  isFavorite: boolean;
  handleClickFavorite: (e) => void;
  className?: string;
};

export const RankingFavorite = ({isFavorite, handleClickFavorite, className}: TFavoriteProps) => (
  <div className={classNames(s.favorite, className)} onClick={handleClickFavorite}>
    {isFavorite ? (
      <IcoStarFilled width={24} height={24} color="yellow500" />
    ) : (
      <IcoStar width={24} height={24} color="gray300" />
    )}
  </div>
);

type TProps = {
  poiName: string;
  categoryName: string;
  isFavorite: boolean;
  handleClickFavorite: (e) => void;
};

const RankingPoiTitle = ({poiName, categoryName, isFavorite, handleClickFavorite}: TProps) => (
  <div className={s.title_wrap}>
    <div className={s.title_container}>
      <span className={s.title}>{poiName}</span>
      <span className={s.category}>{categoryName}</span>
    </div>
    <RankingFavorite isFavorite={isFavorite} handleClickFavorite={handleClickFavorite} />
  </div>
);

export default RankingPoiTitle;
