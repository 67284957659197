import {useState, useCallback, useMemo, useEffect, useRef} from 'react';
import TMapSender, {TQueryItem} from '@lcc/tmap-inapp';
import Header from 'components/Header';
import SearchRecentEditItem from 'components/search/SearchRecentEditItem';
import ModalPopup from 'components/ModalPopup';
import BottomButtons from 'components/BottomButtons';
import ErrorReload from 'components/ErrorReload';
import {EButtonType} from 'types/Button';
import {ERecentItemType, TRecentItem} from 'types/Search';
import {SEARCH_RECENT_MAX_COUNT} from 'constant/Search';
import HybridBridge from 'utils/searchBar';
import {sendSearchClickLog} from 'utils/logManager';
import {useAppLocation} from 'hooks/useAppLocation';
import useRecentQuery from 'hooks/useRecentQuery';
import useDimmedQuery from 'hooks/useDimmedQuery';
import useRecentList from 'hooks/useRecentList';
import {useAppDispatch} from 'ducks/hooks';
import {
  deletePublicTransRecentDestination,
  deleteRecentDestination,
  fetchUserData,
} from 'ducks/userInfo/slice';

import s from 'styles/components/search/SearchRecentListEdit.module.scss';

const SearchRecentListEdit = () => {
  const dispatch = useAppDispatch();
  const {goBack} = useAppLocation();
  const {removeItems} = useRecentQuery();
  const {recentList} = useRecentList({listLength: SEARCH_RECENT_MAX_COUNT});
  const confirmModal = useDimmedQuery({id: 'CONFIRM_DELETE'});
  const [selectItems, setSelectItems] = useState<Array<TRecentItem>>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const refByDeleteConfirm = useRef(false);

  const isSelectAll = useMemo(
    () => selectItems.length === recentList.length,
    [recentList, selectItems]
  );

  const handleSelect = useCallback((n, i) => {
    setSelectItems((prev) => {
      const isSelected = prev.some((item) => item.id === n.id);

      sendSearchClickLog('tap.checkbox', {
        index: isSelected ? 0 : 1,
        list_seq: i,
      });

      if (isSelected) {
        return prev.filter((item) => item.id !== n.id);
      } else {
        return [...prev, n];
      }
    });
  }, []);

  const handleSelectAll = useCallback(() => {
    if (isSelectAll) {
      setSelectItems([]);
      sendSearchClickLog('tap.selectall', {
        index: 0,
      });
    } else {
      setSelectItems([...recentList]);
      sendSearchClickLog('tap.selectall', {
        index: 1,
      });
    }
  }, [isSelectAll, recentList]);

  const handleClickDelete = useCallback(() => {
    if (selectItems.length === 0) {
      return;
    }

    sendSearchClickLog('tap.delete');
    confirmModal.open();
  }, [selectItems]);

  const handleDeleteConfirm = useCallback(async () => {
    const removeDestinations: TRecentItem[] = [];
    const removeQueries: TQueryItem[] = [];
    const removePublicTrans: string[] = [];

    selectItems.forEach((item) => {
      switch (item.type) {
        case ERecentItemType.QUERY: {
          removeQueries.push({query: item.name, date: item.date});
          return;
        }

        case ERecentItemType.POI:
        case ERecentItemType.HOME:
        case ERecentItemType.OFFICE:
        case ERecentItemType.FAVORITE: {
          removeDestinations.push(item);
          return;
        }

        default:
          item?.transportId && removePublicTrans.push(item.transportId);
      }
    });

    if (removeQueries.length > 0) {
      removeItems(removeQueries);
    }

    if (removeDestinations.length > 0) {
      const result: any = await dispatch(deleteRecentDestination({items: removeDestinations}));

      if (result.payload.success) {
        TMapSender.setDirtyRecent();
        dispatch(fetchUserData());
      } else {
        setHasError(true);
      }
    }

    if (removePublicTrans.length > 0) {
      const result = await deletePublicTransRecentDestination({transportIds: removePublicTrans});

      if (result.success) {
        TMapSender.setDirtyRecent();
        dispatch(fetchUserData());
      } else {
        TMapSender.makeToast(result.message || '최근목적지 삭제를 실패하였습니다.');
      }
    }

    sendSearchClickLog('popup_tap.delete_ok', {
      list_num: recentList.length,
      delete_cnt: selectItems.length,
    });
    setSelectItems([]);
    refByDeleteConfirm.current = true;
    confirmModal.close();
  }, [confirmModal, dispatch, removeItems, selectItems, recentList]);

  const handleClickReload = useCallback(() => {
    sendSearchClickLog('tap.fail_refresh');
    window.location.reload();
  }, []);

  useEffect(() => {
    HybridBridge.showSearchBar(false);

    return () => {
      HybridBridge.showSearchBar(true);
    };
  }, []);

  useEffect(() => {
    if (!confirmModal.isOpen && refByDeleteConfirm?.current) {
      goBack();
    }
  }, [confirmModal.isOpen, goBack]);

  return (
    <div className={s.search_recent_edit_wrap}>
      <div className={s.header}>
        <Header
          leftButton={EButtonType.BACK}
          onGoBack={() => {
            sendSearchClickLog('tap.back');
            goBack();
          }}
          title="최근기록 편집"
        />
      </div>

      <div className={s.scroll_wrap}>
        {hasError ? (
          <ErrorReload
            onReload={handleClickReload}
            description={'잠시 후 다시 접속해 주세요.'}
            top={180}
          />
        ) : (
          <>
            {recentList.length < 1 && (
              <div className={s.no_result}>최근 검색한 기록이 없습니다.</div>
            )}
            {recentList.map((n, i) => (
              <SearchRecentEditItem
                data={n}
                isSelect={!!selectItems.find((item) => item.id === n.id)}
                onSelect={() => {
                  handleSelect(n, i);
                }}
                key={n.id}
              />
            ))}
          </>
        )}
      </div>

      <div className={s.bottom_button_wrap}>
        <BottomButtons
          showGradient={true}
          buttons={[
            {
              title: isSelectAll ? '전체 해제' : '전체 선택',
              onClick: handleSelectAll,
            },
            {
              title: '삭제',
              count: selectItems.length > 0 ? selectItems.length : undefined,
              onClick: handleClickDelete,
              disabled: selectItems.length === 0,
              active: selectItems.length > 0,
              className: s.delete,
            },
          ]}
        />
      </div>

      {confirmModal.isOpen && (
        <ModalPopup
          titleComponent={<>장소를 삭제하시겠어요?</>}
          detail="내비 홈의 최근 목적지에도 반영됩니다."
          buttons={[
            {
              children: '취소',
              onClick: () => {
                sendSearchClickLog('popup_tap.delete_cancel');
                confirmModal.close();
              },
            },
            {
              type: 'confirm',
              children: '삭제',
              onClick: handleDeleteConfirm,
            },
          ]}
          onClickClose={() => {
            confirmModal.close();
          }}
        />
      )}
    </div>
  );
};

export default SearchRecentListEdit;
